import React, { useRef, useState, useCallback } from 'react';
import classes from './SearchPublications.module.css';
import SearchPublicationCardLoader from './SearchPublicationCardLoader'
import PublicationCard from '../../publications/Components/PublicationCard';
import PublicationComparison from './PublicationComparison';
import AlertTooltip from '../../publications/Components/UI/AlertTooltip';
import _ from 'lodash'; // Import lodash

// Services
import { getPublications } from 'services/publications';

// Images
import IconSearch from '../../../assets/img/icons/search.png';
import EmptyPublicationsIcon from '../../../assets/icons/EmptyPublications.svg';
import SuggestionsIcon from '../../../assets/img/icons/spark-icon.svg';


const SearchPublications = ({ searchView, setSearchView, setCopyResultText, setCopyCreationResult, selectedPublication, setSelectedPublication, loadingCompetition, setLoadingCompetition, setRivalId, setWantDelete, setSelectedRival }) => {
    const [searchInput, setSearchInput] = useState("");
    const [isLoadingPublications, setIsLoadingPublications] = useState(false);
    const [publications, setPublications] = useState([]);
    const requestIdRef = useRef(0);
    const numberOfPublications = 10;

    const suggestionsTooltip = `<div style="width: 300px;">
        <div style="width: 250px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
            <p style="margin: 0;">
                <span>
                    <span style="font-weight: 500;">
                        ¡Tenemos sugerencias para esta publicación!
                    </span>
                    <br>
                    <span style="font-weight: 300; font-family: Montserrat;">
                        Instance Analytics se basa en la competencia para generar sugerencias de mejora en tus publicaciones
                    </span>                
                </span>
            </p>
        </div>
    </div>`;

    const debouncedSearch = useCallback(
        _.debounce((query) => {
            const newRequestId = Date.now();
            requestIdRef.current = newRequestId;
            searchHandle(query, newRequestId);
        }, 1000),
        []
    );

    const handleSearchInput = (event) => {
        const newValue = event.target.value;
        setSearchInput(newValue);

        if (newValue.length >= 3) {
            setIsLoadingPublications(true);
            debouncedSearch(newValue);
        } else {
            setIsLoadingPublications(false);
            setPublications([]);
        }
    };

    const searchHandle = async (query, id) => {
        let newQuery = `?general_search_fields=product_name,brand_name,ean,sku,category_name,id_publication&general_search_value=${query}&has_ws_competition=true`;
        newQuery = newQuery.concat(`&page_size=${numberOfPublications}&page=1&sort_by=date&sort_direction=DESC`);

        try {
            const response = await getPublications(newQuery);
            
            // Check if the response corresponds to the latest request
            if (id === requestIdRef.current) {
                setIsLoadingPublications(false);
                setPublications(response?.data?.publications || []);
            }
        } catch (error) {
            console.error("Error fetching publications:", error);
            setIsLoadingPublications(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const renderCardCheckContainer = (product) => {
        if(product.stock > 0){//CAMBIAR CONDICIÓN A PRODUCTOS CON RECOMENDACIONES.... FLAG O LISTA EN LA DB!!!!!
            return <div className={classes.cardCheckContainer}>
                        <div className={classes.sparkContainer}>
                            <AlertTooltip tooltipContent={suggestionsTooltip}>
                                <img
                                    className={classes.sparkIcon}
                                    alt="sugerencias"
                                    src={SuggestionsIcon}
                                />
                            </AlertTooltip>
                        </div>
                    </div>;
        }else{
            return <div className={classes.cardCheckContainer}></div>;
        }        
    };

    return (
        <div className={classes.mainContainer}>
            {
                    searchView ? (                        
                        <div className={classes.secondContainer}>
                            <div className={classes.searchHeaderContainer}>
                                <div className={classes.titleHeaderContainer}>
                                    <span>Esto <span className={classes.notGoogleText}>no</span> es Google</span>
                                </div>
                                <div className={classes.subtitleHeaderContainer}>
                                    <div className={classes.subtitleText1Container}>
                                        <span>Elige una de tus publicaciones</span>
                                    </div>
                                    <div className={classes.subtitleText2Container}>
                                        <span>{`y te mostramos información de la competencia :)`}</span>
                                    </div>
                                </div>
                                <div className={classes.searchBarHeaderContainer}>
                                    <div className={classes.searchBarContainer}>
                                        <div className={classes.searchBar}>
                                            <div className={classes.iconSearchWrapper}>
                                                <img className={classes.searchIcon} src={IconSearch} alt="Search Icon" />
                                            </div>
                                            <div className={classes.searchInputWrapper}>
                                                <input
                                                    type="text"
                                                    name="search"
                                                    value={searchInput}
                                                    onChange={handleSearchInput}
                                                    onKeyDown={handleKeyDown}
                                                    className={classes.searchInput}
                                                    placeholder="Busca por nombre de producto, marca, tienda, SKU, EAN"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                            <div className={classes.publicationsContainer}>
                                <div className={classes.innerPublicationsContainer}>
                                    { isLoadingPublications ? 
                                        (
                                            Array.from(Array(numberOfPublications).keys()).map((item) => (
                                                <SearchPublicationCardLoader key={item} />
                                            ))
                                        ) : 
                                        (                                           
                                            publications?.length > 0 ? (
                                                publications.map(element => (
                                                    <PublicationCard 
                                                        product={element} 
                                                        key={element?.id_publication}   
                                                        cardWrapper={classes.cardWrapper}                                                                            
                                                        renderCardCheckContainer={renderCardCheckContainer}
                                                        titleInfo={classes.titleInfo}
                                                        cardImgWrapper={classes.cardImgWrapper}
                                                        titleInfoHealthBuybox={classes.titleInfoHealthBuybox}
                                                        flexWrapper={classes.flexWrapper}
                                                        callerComponent='SearchPublications'
                                                        setSearchView={setSearchView}
                                                        setCopyResultText={setCopyResultText}
                                                        setCopyCreationResult={setCopyCreationResult}
                                                        setSelectedPublication={setSelectedPublication}
                                                        setLoadingCompetition={setLoadingCompetition}
                                                    ></PublicationCard>
                                                )) 
                                            ) : 
                                            (
                                                searchInput?.length > 2 && (
                                                    <div className={classes.noPublicationsContainer}>
                                                        <img
                                                            className={classes.publicationNotFoundIcon}
                                                            src={EmptyPublicationsIcon}
                                                            alt="empty-publications-icon"
                                                        />
                                                        <div className={classes.publicationNotFoundtextContainer}>
                                                            <span>Vaya... busco y busco pero no</span>
                                                            <span>encuentro publicaciones con ese nombre</span>
                                                        </div>
                                                    </div>
                                                )
                                            )                                       
                                        )
                                    }
                                </div>                                
                            </div>
                        </div>
                    ) :
                    (
                        <div className={classes.secondContainer}>
                            <PublicationComparison
                            selectedPublication={selectedPublication}
                            loadingCompetition={loadingCompetition}
                            setLoadingCompetition={setLoadingCompetition}
                            setCopyResultText={setCopyResultText} 
                            setCopyCreationResult={setCopyCreationResult}
                            setRivalId={setRivalId}
                            setWantDelete={setWantDelete}
                            setSelectedRival={setSelectedRival}
                            />
                        </div>                        
                    )
                }            
        </div>
    );
}

export default SearchPublications;
