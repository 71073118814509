import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Skeleton, Box } from '@mui/material';
import CreateAlertModal from './CreateAlertModal';
import Swal from "sweetalert2"
import AlertSeller from '../../../components/UI/AlertSeller';
import Filters from "./Filters"
import classes from './PublicationsList.module.css'
import Select from "../../../components/UI/Select2";
import PublicationCard from "./PublicationCard";
import PublicationCardLoader from "./PublicationCardLoader"
import DownloadsCenter from '../../../components/DownloadsCenter/DownloadsCenter';
import AlertsCenter from '../../../components/PublicationAlertsCenter/AlertsCenter';
import Pagination from "../../../components/UI/Pagination"
import noProductsInCatalogImg from "../../../assets/img/searches/no-results-products.svg"
import downArrowIcon from '../../../assets/img/icons/select-model-icon.svg';

//Services
import { getFilters } from 'services/publications'
import { getPublications, getBuyboxFile, getPublicationsFile } from 'services/publications'
import { getAlerts } from 'services/alerts'


//data
import { publicationsSearchBarLoader, loaderSquaredFilterButtonData, publicationsFiltersLoaderItemData } from "../../../utils/loaders/loadersData"

//images
import iconSearch from "../../../assets/img/icons/search.png"
import iconClean from "../../../assets/img/icons/Close.png"
import filtersIcon from "../../../assets/img/icons/Filtros.png"
import DowloadIcon from "../../../assets/img/icons/dowloadIcon.png"
import Close from "../../../assets/img/icons/close_black.png"
import CloseModal from "../../../assets/img/icons/CloseModal.svg"
import interrogaIlustra from "../../../assets/img/interroga.png"

const PublicationsList = ({ setViewTitle, setIsEditView, isEditView, publicationsToSkip, setPublicationsToSkip, returnOriginalPage }) => {
    //Pagination
    const pagination = useRef({
        total: 0,
        pageSize: 10,
        page: 1
    });

    //Data
    const accountStoresString = localStorage.getItem("st");
    const accountStores = accountStoresString.split(',').map(Number);
    const [countries, setCountries] = useState([]);
    const [publications, setPublications] = useState([]);
    const data = useRef([]);
    const [loadingPublications, setLoadingPublications] = useState(true);
    const [loadingInititalPublications, setLoadingInitialPublications] = useState(true)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [wantDownload, setWantDownload] = useState(false);
    const query = useRef("?");
    const [isDownloadingBuyBox, setIsDownloadingBuyBox] = useState(false)
    const [isDownloadingPublications, setIsDownloadingPublications] = useState(false)
    const [foundResultsInFirstLoad, setFoundResultsInFirstLoad] = useState(false)
    const [checkedPublications, setCheckedPublications] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectAllPagesChecked, setSelectAllPagesChecked] = useState(false);
    const [deselectedIds, setDeselectedIds] = useState([]);
    const [totalPublications, setTotalPublications] = useState(0);
    const [alertCreationResult, setAlertCreationResult] = useState(null);
    const [alertCreationResultText, setAlertCreationResultText] = useState("");
    const [triggerSelectAll, setTriggerSelectAll] = useState(false);
    const [firstModal, setFirstModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);  
    const [alertSByPublication, setAlertSByPublication] = useState([]);
    const [actionsForDownloads, setActionsForDownloads] = useState({
        refreshDownloads: false,
        unfoldDownloadCenter: false,
    })
    const [alertTime, setAlertTime] = useState(4500);
    const [alertsCenterData, setAlertsCenterData] = useState([]);
    const [allMyAlerts, setAllMyAlerts] = useState([]);
    const [isEditAlertView, setIsEditAlertView] = useState(false);
    const [alertTypes, setAlertTypes] = useState([]);
    const [parentFunctionToCallChild, setParentFunctionToCallChild] = useState(false);
    const [isButtonHidden, setIsButtonHidden] = useState(true);
    const [resetEditAlertData, setResetEditAlertData] = useState(false);
    const [currentAlertIdEdit, setCurrentAlertIdEdit] = useState('');
    const [loadingAddPublicationsAlert, setLoadingAddPublicationsAlert] = useState(false);
    const [copyResultText, setCopyResultText] = useState('');
    const [copyCreationResult, setCopyCreationResult] = useState(false);


    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertType, setAlertType] = useState("");

    //Errors
    const [globalError, setGlobalError] = useState(undefined);

    //Sort
    const [selectedOptionSort, setSelectedOptionSort] = useState("");
    const sortingObjDefaultValue = { sortBy: "publication_creation_date", direction: "desc"}
    let [sortingObj, setSortingObj] = useState(sortingObjDefaultValue);

    const sortOptions = [
        { value: "price", name: "Menor Precio", direction: "asc" },
        { value: "price", name: "Mayor Precio", direction: "desc" },
        { value: "publication_creation_date", name: "Fecha de creación más reciente", direction: "desc" },
        { value: "publication_creation_date", name: "Fecha de creación menos reciente", direction: "asc" },
        { value: "sellercenter_sorting.healthcheck", name: "Mejor Healthcheck", direction: "desc" },
        { value: "sellercenter_sorting.healthcheck", name: "Peor Healthcheck", direction: "asc" },
        { value: "sellercenter_sorting.buybox", name: "Mejor Buybox", direction: "desc" },
        { value: "sellercenter_sorting.buybox", name: "Peor Buybox", direction: "asc" }
    ];

    //Search
    const [searchInput, setSearchInput] = useState("");
    const hasSearchedRef = useRef(false)

    //displayFilters
    const [filtersOn, setFiltersOn] = useState(false);
    const [isMobileSizes, setIsMobileSized] = useState(false);
    const currentStore = useRef("");
    const currentCountry = useRef("");
    const modalRef = useRef(null);

    const toggleModalDownload = () => setWantDownload(!wantDownload);    

    //Init
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        // set initial value
        const mediaWatcher = window.matchMedia("(max-width: 820px)");
        setIsMobileSized(mediaWatcher.matches);

        //watch for updates
        function updateIsNarrowScreen(e) {
            setIsMobileSized(e.matches);
        }
        mediaWatcher.addEventListener("change", updateIsNarrowScreen);

        // clean up
        return function cleanup() {
            mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
        };
    });

    useEffect(() => {
        if (isMobileSizes) {
            setFiltersOn(false)
        }
        if (!isMobileSizes) {
            setFiltersOn(true)
        }
    }, [isMobileSizes]);    

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setIsSelectOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (triggerSelectAll) {
            const allPublicationIds = data.current.map(pub => pub.id_publication);
            const allPublicationClientId = [...new Set(data.current.map(pub => pub.client_id))];
            setCheckedPublications(allPublicationIds);
            setClientIds(allPublicationClientId);
            setTriggerSelectAll(false);
        }
    }, [triggerSelectAll, data.current]);

    const clearSort = () => {
        handleDeSelectAllOnPage();
        setSelectedOptionSort("");
        //it's assigned in both ways because an instant change in the value is needed for the request
        sortingObj = sortingObjDefaultValue
        setSortingObj(sortingObjDefaultValue)
    }

    const setCurrentPaginationData = (data) => {
        pagination.current = {
            total: data?.total || 0,
            pageSize: data?.page_size || 10,
            page: data?.page || 1
        }
    }

    const handleOptionsSort = (value, sortDirection, calledFrom=null) => {
        let sortOption = sortOptions.find(e => e.value === value)
        if(calledFrom === null)
            sortOption = sortOptions.find(e => e.name === value)

        setSelectedOptionSort(sortOption?.name)

        setSortingObj({ sortBy: sortOption.value, direction: sortDirection })
    }

    const handleErrorModalDownload = () => {
        Swal.fire({
            icon: 'error',
            title: 'Error inesperado',
            text: 'Por favor vuelve a intentar',
            timer: 10000,
            closeButtonHtml: `<img src="${Close}" />`
        })
    }

    useEffect(() => {
        if (alertCreationResult) {
            if(isEditView){
                setLoadingAddPublicationsAlert(false);
                getPublicationsToAdd(currentAlertIdEdit);
                returnOriginalPage();
                setAlertType("success");
                handleAlertCreation(alertCreationResultText); 
            }else{
                setAlertType("success");
                handleAlertCreation(alertCreationResultText); 
            }                       
        }else if(alertCreationResult === false){
            setAlertType("error");
            handleAlertCreation(alertCreationResultText);
        }else{
            //if alertCreationResult is null
        }
    }, [alertCreationResult]);

    const handleAlertCreation = (alertText) => { 
        setShowAlert(true);
        handleDeSelectAllOnPage();
        setAlertText(alertText);
        handleGetAlerts();
        setAlertCreationResult(null);
    }

    useEffect(() => {
        if (copyCreationResult) {
            setAlertTime(3000);
            setAlertType("success");
            handleAlertCopy(copyResultText);           
        }else{            
        }
    }, [copyCreationResult]);

    const handleAlertCopy = (alertText) => { 
        setShowAlert(true);
        setAlertText(alertText);
        setCopyCreationResult(false);
    }
 
    const downloadPublicationsFileHandler = async () => {
        try {

            let queryString = ""
            if(selectAllPagesChecked && deselectedIds?.length){
                queryString= "&publications_to_exclude=" + deselectedIds?.join(",")
            }
            else if(selectAllPagesChecked && !deselectedIds?.length){
                queryString= "&include_all=true"
            }
            else if(!selectAllPagesChecked && checkedPublications?.length){
                queryString= "&publications_to_include=" + checkedPublications?.join(",")
            }
            else{
                queryString = query?.current
            }

            setIsDownloadingPublications(true)
            const response = await getPublicationsFile((queryString?.replace("?", "&") || "") + "&processing_type=queue")
            setIsDownloadingPublications(false)
            if (response instanceof Error)
                handleErrorModalDownload()
            else {
                setActionsForDownloads({
                    ...actionsForDownloads,
                    unfoldDownloadCenter: true,
                    refreshDownloads: true
                })
                toggleModalDownload()   
            }
        } catch (error) {
            setIsDownloadingBuyBox(false)
        }
    }

    const handleSort = async (type = "publication_creation_date", direction = "desc") => {
        const sortingDetailString = getSortingDetailString(type, direction) 
        const separator = getQueryStringInitialSeparator(query.current)
        let newQuery = `${query.current}` + `${separator}${sortingDetailString}`

        handleOptionsSort(type, direction, "handleSort")

        setLoadingPublications(true)
        setGlobalError(undefined)
        
        newQuery = newQuery.concat(`&page_size=${pagination.current.pageSize}${publicationsToSkip}`)
        const response = await getPublications(newQuery)
        setPublications(response?.data?.publications || [])
        //handleGetAlerts(response?.data?.publications);
        data.current = response?.data?.publications || [];

        setCurrentPaginationData(response?.data)
        setLoadingPublications(false)
    }

    const getSortingDetailString = (type, direction) =>{
        try {
            // REMEMBER: "winning", "sharing_first_place" is WINNING, everything is LOSING. That's why this works
            
            return `sort_by=${type}&sort_direction=${direction}`
        } catch (error) {
            return ""
        }
    }

    const getQueryStringInitialSeparator = (currentQueryString) => {
        return currentQueryString?.includes("?") ? "&" : "?"
    }

    const setRowsHandle = (rows) => {
        pagination.current.pageSize = rows;
    }

    const onChangeRowsPerPage = async (rows) => {
        //setAlertSByPublication([]);
        setLoadingPublications(true)
        setGlobalError(undefined)
        const separator = getQueryStringInitialSeparator(query.current)
        const newQuery = `${query.current}${separator}page_size=${rows}`
        const response = await getPublications(newQuery)        
        setPublications(response?.data?.publications || [])  
        //handleGetAlerts(response?.data?.publications);      
        setCurrentPaginationData(response?.data)
        data.current = response?.data?.publications || [];
        setLoadingPublications(false)        
    }

    const getData = async () => {
        try {
           await Promise.allSettled([
            getInitialPublications(),
            getFiltersData()
           ])
        } catch (error) {
            setGlobalError(error.message)
        }
    }

    const getInitialPublications = async () => {
        try {
            setLoadingInitialPublications(true)
            setLoadingPublications(true)
            setGlobalError(undefined)

            const response = await getPublications();
            if (response instanceof Error) throw response

            setPublications(response?.data?.publications ||[]); 
            handleGetAlerts();                  
            data.current = response?.data?.publications || [];

            if(!response?.data?.publications.length)
                setFoundResultsInFirstLoad(false)
            else
                setFoundResultsInFirstLoad(true)
            
            setCurrentPaginationData(response?.data)
            setLoadingPublications(false)
            setLoadingInitialPublications(false)            
            
        } catch (error) {
            setGlobalError(error.message)
            setLoadingPublications(false)
            setLoadingInitialPublications(false)
        }
    }

    const getFiltersData = async () => {
        try {
            setLoadingFilters(true)
            const filters = await getFilters()
            setLoadingFilters(false)
            setCountries(filters.data)
        } catch (error) {
            setGlobalError(error.message)
            setLoadingFilters(false)
        }
    }


    const setFilters = async (store, country, category, brand, channel, state) => {
        handleDeSelectAllOnPage();
        setLoadingPublications(true)
        setGlobalError(undefined)
        try {
            const sortingQueryString = getSortingDetailString(sortingObj?.sortBy, sortingObj.direction)

            const queryStringForFilters = getQueryStringFilters({ store, country, category, brand, channel, state })            
            let newQuery = `?${queryStringForFilters}`

            query.current = newQuery

            newQuery = newQuery + `&page_size=${pagination.current.pageSize}${publicationsToSkip}`
            newQuery = newQuery + `&${sortingQueryString}`

            const response = await getPublications(newQuery)
            if (response instanceof Error) throw response


            setPublications(response?.data?.publications || [])
            //handleGetAlerts(response?.data?.publications);
            data.current = response?.data?.publications || [];

            if(!category && !brand && !response?.data?.publications?.length)
                setFoundResultsInFirstLoad(false)
            else 
                setFoundResultsInFirstLoad(true)

            setCurrentPaginationData(response?.data)
            setLoadingPublications(false)
        } catch (error) {
            setGlobalError(error.message)
            setLoadingPublications(false)
        }
    }

    const handleGetAlerts = async () => {        
        try {
            const response = await getAlerts(`?user=${localStorage.getItem("name")}`);
    
            if (response.data.length > 0) {
                // Filter out alerts with enabled = false
                const enabledAlerts = response.data.filter(item => item.enabled);
    
                setAlertSByPublication([]);
                setAlertsCenterData([]);
                setAllMyAlerts([]);
    
                setAllMyAlerts(enabledAlerts);
    
                const cleanedData = enabledAlerts.map(item => ({
                    alert_id: item.id,
                    alert_name: item.alert_name,
                    alert_creation_date: item.alert_creation_date,
                    alert_creator: item.alert_creator,
                    alert_publications: item.alert_publications.map(pub => pub.id_publication)
                }));
    
                // Sort cleanedData by alert_creation_date from newer to oldest
                cleanedData.sort((a, b) => new Date(b.alert_creation_date) - new Date(a.alert_creation_date));
    
                const transformedData = cleanedData.flatMap(alert => 
                    alert.alert_publications.map(publication => ({
                        id_publication: publication,
                        alert_name: alert.alert_name
                    }))
                );
    
                setAlertSByPublication(transformedData);
    
                const filteredData = cleanedData.filter(item => item.alert_creator === localStorage.getItem("name"));
                setAlertsCenterData(filteredData);
            } 
        } catch (error) {
            setGlobalError(error.message);
        } finally {
            // Any cleanup or final steps if necessary
        }
    };
    
    

    const getQueryStringFilters = (filtersData) => {
        try {
            const  { store, country, category, brand, channel, state } = filtersData
            let queryString = ""
            if(store)
                queryString = queryString + (queryString != "" ? `&store_id=${store}` : `store_id=${store}`)
            if(country)
                queryString = queryString + (queryString != "" ? `&country_id=${country}` : `country_id=${country}`)
            if(category)
                queryString = queryString + (queryString != "" ? `&category_name=${category}` : `category_name=${category}`)
            if(brand)
                queryString =  queryString + (queryString != "" ? `&brand_name=${brand}` : `brand_name=${brand}`)
            if(channel)
                queryString = queryString + (queryString != "" ? `&channel_id=${channel}` : `channel_id=${channel}`)
            if(state)
                queryString = queryString + (queryString != "" ? `&translated_status=${state}` : `translated_status=${state}`)

            return queryString
        } catch (error) {
            return ""
        }
    }

    const getPublicationsToAdd = async (queryPublications, alertPublications) => {

        handleDeSelectAllOnPage();
        setLoadingPublications(true)
        setGlobalError(undefined)

        let alertQuery = alertPublications?.length > 0 ? `&alert_id=${queryPublications}&alert_publications=ONLY_PUBLICATIONS_NOT_IN_ALERT` : '?';

        setPublicationsToSkip(alertQuery);
        const sortingQueryString = getSortingDetailString(sortingObj?.sortBy, sortingObj.direction)

        let newQuery = alertQuery
        query.current = newQuery

        newQuery = newQuery.concat(`&page_size=${pagination.current.pageSize}&${sortingQueryString}`)

        const response = await getPublications(newQuery)        

        hasSearchedRef.current = true

        setPublications(response?.data?.publications || [])
        data.current = response?.data?.publications || [];
        setCurrentPaginationData(response?.data)
        setLoadingPublications(false)
    }

    const searchHandle = async (event) => {
        handleDeSelectAllOnPage();
        event.preventDefault()
        if(!searchInput?.length)
            return

        setLoadingPublications(true)
        setGlobalError(undefined)
        const sortingQueryString = getSortingDetailString(sortingObj?.sortBy, sortingObj.direction)

        let newQuery = `?general_search_fields=product_name,brand_name,ean,sku,category_name,id_publication&general_search_value=${searchInput}`
        query.current = newQuery

        newQuery = newQuery.concat(`&page_size=${pagination.current.pageSize}&${sortingQueryString}${publicationsToSkip}`)


        const response = await getPublications(newQuery)        

        hasSearchedRef.current = true

        setPublications(response?.data?.publications || [])
        //handleGetAlerts(response?.data?.publications);
        data.current = response?.data?.publications || [];
        setCurrentPaginationData(response?.data)
        setLoadingPublications(false)
    }

    const onChangePage = async (pageClicked) => {
        //setAlertSByPublication([]);
        setLoadingPublications(true)
        window.scrollTo(0, 0);

        const sortingQueryString = getSortingDetailString(sortingObj?.sortBy, sortingObj.direction)
        const separator = getQueryStringInitialSeparator(query.current)
        let newQuery = `${query.current}${separator}page=${pageClicked}&page_size=${pagination.current.pageSize}&${sortingQueryString}`

        const response = await getPublications(newQuery)
        setPublications(response?.data?.publications || [])  
        //handleGetAlerts(response?.data?.publications);      
        if (pageClicked > pagination.current.page)
            data.current = data.current.concat(response?.data?.publications || [])
        pagination.current.page = pageClicked;
        setLoadingPublications(false)        
    }

    const handleSearchInput = (event) => {
        event.preventDefault()
        setSearchInput(event.target.value)
    }

    const clearInput = () => {
        handleDeSelectAllOnPage();
        if(searchInput?.length){
            query.current = ""
            setSearchInput("");
        }

        if(hasSearchedRef.current){
            getData();
            query.current = ""
            setSearchInput("");
        }
    }

    const toggleFiltersOn = () => {
        setFiltersOn(!filtersOn)
    }    
      
    const toggleSelect = () => {
        setIsSelectOpen(!isSelectOpen);
    };

    const handleCheckboxChange = (productId, client_id, isChecked) => {        
        setCheckedPublications(prevChecked => {
            if (isChecked) {                
                return [...prevChecked, productId];
            } else {
                return prevChecked.filter(id => id !== productId);
            }
        });

        setClientIds(prevClientIds => {
            if (isChecked && !prevClientIds.includes(client_id)) {
                return [...prevClientIds, client_id];
            } else if (!isChecked) {
                return prevClientIds.filter(id => id !== client_id);
            }
            return prevClientIds;
        });
    };

    const handleSelectAll = () => {
        setTotalPublications(pagination.current.total);

        const params = new URLSearchParams(query.current);
        const storeId = params.get('store_id');
        
        if (storeId) {
            setClientIds([parseInt(storeId, 10)]);
        } else {
            setClientIds(accountStores);
        }

        if(selectAllPagesChecked && checkedPublications.length > 0){
            handleDeSelectAllOnPage();
        }

        setSelectAllPagesChecked(!selectAllPagesChecked);
    };
    

    const handleSelectAllOnPage = () => {        
        setIsSelectOpen(false);

        if(selectAllPagesChecked){
            handleDeSelectAllOnPage();
        }
        
        setTriggerSelectAll(true);
    };

    const handleDeSelectAllOnPage = () => {
        setIsSelectOpen(false);
        setTotalPublications(0);
        setSelectAllPagesChecked(false);
        setDeselectedIds([]);
        setCheckedPublications([]);
        setClientIds([]);
    };

    const toggleCreateAlertModal = () => {  
        setResetEditAlertData(true);      
        if(clientIds.length > 1){
            setWarningModal(prevState => !prevState)
        }else{
            setFirstModal(prevState => !prevState);
        }        
    };

    const saveNewPublications = () => {
        setLoadingAddPublicationsAlert(true);
        setParentFunctionToCallChild(true);            
    }

    const isButtonEnabled = checkedPublications.length > 0 || selectAllPagesChecked;
    
    useEffect(() => {
        if (isButtonEnabled) {
            setIsButtonHidden(true);
        }else{
            setIsButtonHidden(false);
        }
    }, [isButtonEnabled]);


    return (
            <div>
                {showAlert && <AlertSeller showAlert={showAlert} text={alertText} setShowAlert={setShowAlert} time={alertTime} type={alertType}/>}

                {
                    filtersOn && (
                        <>
                            <Filters className={classes.filterToggle}
                                countries={countries}
                                handleCountries={() => setCountries()}
                                setFilters={setFilters}
                                dowload={toggleModalDownload}
                                clearSort={clearSort}
                                setStoreExcel={(store)=>{currentStore.current = store}}
                                setCountryExcel={(country)=>{currentCountry.current = country}}
                                loadingFilters={loadingFilters}
                                loadingPublications={loadingInititalPublications}
                            />
                            {isMobileSizes ?
                                !loadingInititalPublications ?
                                    <div className={classes.sortWapperMobile}>
                                        <Select 
                                            filterName="Ordenar por"
                                            options={sortOptions}
                                            selectedOption={selectedOptionSort}
                                            handleOptions={handleOptionsSort}
                                            handle={handleSort}
                                            name="name"
                                            sortByName={false}
                                        />
                                    </div>
                                :
                                    <Skeleton {...publicationsFiltersLoaderItemData.propsComponent} style={publicationsFiltersLoaderItemData.style} />  
                            :
                                null
                            }
                        </>
                    )
                }
                <div className={classes.searchAndSortWrapper}> 
                    <div className={classes.relativeContainer}>
                        <div className={classes.buttonNoBorderWrapper}>
                            <button className={classes.buttonNoBorder} onClick={toggleSelect}>
                                <img src={downArrowIcon} alt="Down Arrow" />
                            </button>
                        </div>
                        {isSelectOpen && (
                            <div ref={modalRef} className={classes.modalSelect}>
                                <div className={classes.todoTextContainer} onClick={handleSelectAllOnPage}>
                                    <span className={classes.todoText}>Todo</span>
                                </div>                                
                                <div className={classes.separatorHorizontal}></div>
                                <div className={classes.nadaTextContainer} onClick={handleDeSelectAllOnPage}>
                                    <span className={classes.nadaText}>Nada</span>
                                </div>                                
                            </div>
                        )}
                    </div>  
                    {!isEditView && (
                        <button className={classes.buttonAlert} disabled={!isButtonEnabled} onClick={toggleCreateAlertModal}>
                            <div className={classes.buttonAlertInsideContainer}>
                                <span className={classes.buttonFilterText}>Crear alerta</span>
                            </div>                        
                        </button>
                    )}   
                    {isEditView && !loadingAddPublicationsAlert && (
                        <button className={classes.buttonAlert} disabled={!isButtonEnabled} onClick={saveNewPublications}>
                            <div className={classes.buttonAlertInsideContainer}>
                                <span className={classes.buttonFilterText}>Guardar cambios</span>
                            </div>                        
                        </button>
                    )}  
                    {isEditView && loadingAddPublicationsAlert && (
                        <div className={classes.buttonAlertInsideContainerLoading} disabled={true}>
                            <CircularProgress
                            thickness={5}
                            size={20}
                            className={classes.circularProgressCustom}
                            />
                            <b >Cargando</b>
                        </div> 
                    )} 
                    <CreateAlertModal 
                        warningModal={warningModal}
                        setWarningModal={setWarningModal}
                        firstModal={firstModal} 
                        setFirstModal={setFirstModal}
                        toggleCreateAlertModal={toggleCreateAlertModal}
                        setGlobalError={setGlobalError}
                        Skeleton={Skeleton}
                        filter={query.current}
                        clientIds={clientIds}
                        checkedPublications={checkedPublications}
                        selectAllPagesChecked={selectAllPagesChecked}
                        deselectedIds={deselectedIds}
                        setAlertCreationResult={setAlertCreationResult}
                        setAlertTypes={setAlertTypes}
                        setAlertCreationResultText={setAlertCreationResultText}
                    />
                    {!isMobileSizes ?      
                        <form onSubmit={searchHandle} className={classes.searchWrapper}>
                            <div className={classes.labelWrapper}>
                            {loadingInititalPublications ?
                                <Skeleton {...publicationsSearchBarLoader.propsComponent} style={publicationsSearchBarLoader.style} />
                            :
                                <label className={classes.searchLabel}>
                                <div className={classes.iconSearchWrapper}>
                                    <img className={classes.img} src={iconSearch} />
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    value={searchInput}
                                    onChange={event => handleSearchInput(event)}
                                    className={classes.searchInput}
                                    placeholder="Busca por producto, SKU, EAN, marca o categoría."
                                />
                                <div className={classes.iconCleanWrapper} onClick={clearInput}>
                                    <img className={classes.img} src={iconClean} />
                                </div>
                            </label>
                            }
                            </div>
                        </form>                    
                    :
                        null
                    }                                    
                    {!isMobileSizes ?
                        !loadingInititalPublications ? 
                            <div className={classes.sortWrapper}>
                                <Select 
                                    filterName="Ordenar por"
                                    options={sortOptions}
                                    selectedOption={selectedOptionSort}
                                    handleOptions={handleOptionsSort}
                                    handle={handleSort}
                                    name="name"
                                    sortByName={false}
                                />
                            </div>
                        :
                            <Skeleton {...publicationsFiltersLoaderItemData.propsComponent} style={publicationsFiltersLoaderItemData.style} />  
                    :
                        null
                    }                    
                    <div className={classes.filtersButtonWrapper}>
                        {loadingFilters ?
                            <Skeleton {...loaderSquaredFilterButtonData.propsComponent} style={loaderSquaredFilterButtonData.style} />
                        :
                            <div className={classes.buttonIcon} onClick={() => toggleFiltersOn()}>
                                <img className={classes.buttonFilterText} src={filtersIcon} />
                            </div>
                        }
                        {loadingInititalPublications ?
                            <Skeleton {...loaderSquaredFilterButtonData.propsComponent} style={loaderSquaredFilterButtonData.style} />
                        :
                            <div className={classes.buttonIcon} onClick={() => toggleModalDownload()}>
                                <img className={classes.buttonFilterText} src={DowloadIcon} />
                            </div>
                        }
                    </div>
                </div>
                {loadingPublications ?
                    <div className={classes.publicationsWrapper}>
                        {Array.from(Array(10).keys()).map((item) => (
                            <PublicationCardLoader key={item} />
                        ))}
                    </div>
                :
                    null
                }
                {!loadingPublications && (
                    publications?.length ?
                    <>
                        <div className={classes.publicationsWrapper}>
                            {   
                                publications.map(element => (
                                    <PublicationCard 
                                        product={element} 
                                        key={element?.id_publication} 
                                        checked={(!selectAllPagesChecked && checkedPublications.includes(element?.id_publication) || (selectAllPagesChecked))}
                                        onCheckboxChange={handleCheckboxChange}
                                        deselectedIds={deselectedIds}
                                        setDeselectedIds={setDeselectedIds}
                                        selectAllPagesChecked={selectAllPagesChecked}  
                                        alertSByPublication={alertSByPublication}  
                                        setCopyResultText={setCopyResultText}
                                        setCopyCreationResult={setCopyCreationResult}                                 
                                    ></PublicationCard>
                                ))
                            }
                        </div>                        
                        <div>                                                   
                            <Pagination
                                total={pagination.current.total}
                                isLoading={loadingPublications}
                                page={pagination.current.page}
                                setPage={onChangePage}
                                rowsPerPage={pagination.current.pageSize}
                                setRowsHandle={setRowsHandle}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                name={"Publicaciones"}
                                checkedCount={selectAllPagesChecked ? (totalPublications - deselectedIds.length) : checkedPublications.length}
                                onSelectAll={handleSelectAll}
                                selectAllPagesChecked={selectAllPagesChecked}
                            ></Pagination>                            
                        </div>
                    </>
                    :
                    <div className="no-data-found-container">
                        <div>
                        <img src={noProductsInCatalogImg} className={"no-results-image"} />
                        </div>
                        <span className="no-data-found-text">¡Diantres...!.</span>
                        <span className="no-data-found-text">{!foundResultsInFirstLoad ? "Aún no hay publicaciones disponibles" : "No se encontraron resultados"}</span>
                    </div>
                )}
                {wantDownload && (
                    <ModalDownload
                        toggleModalDownload={toggleModalDownload}
                        wantDownload={wantDownload}
                        downloadPublicationsFileHandler={downloadPublicationsFileHandler}
                        isDownloadingPublications={isDownloadingPublications}
                        selectAllPagesChecked={selectAllPagesChecked}
                        deselectedIds={deselectedIds}
                        checkedPublications={checkedPublications}
                        queryString={query?.current || ""}
                    ></ModalDownload>
                )}

                <DownloadsCenter
                    actionsForDownloads={actionsForDownloads}
                    setActionsForDownloads={setActionsForDownloads}
                />

                <AlertsCenter
                    myAlertsCounter={alertsCenterData?.length}
                    alerts={alertsCenterData}
                    allMyAlerts={allMyAlerts}
                    filter={query.current}
                    selectAllPagesChecked={selectAllPagesChecked}
                    clientIds={clientIds}
                    deselectedIds={deselectedIds}
                    checkedPublications={checkedPublications}
                    setAlertCreationResult={setAlertCreationResult}
                    setAlertCreationResultText={setAlertCreationResultText}
                    setGlobalError={setGlobalError}
                    isEditView={isEditView}
                    setViewTitle={setViewTitle}
                    setIsEditView={setIsEditView}
                    getPublicationsToAdd={getPublicationsToAdd}
                    parentFunctionToCallChild={parentFunctionToCallChild}
                    setParentFunctionToCallChild={setParentFunctionToCallChild}
                    isButtonHidden={isButtonHidden}
                    toggleCreateAlertModal={toggleCreateAlertModal}
                    resetEditAlertData={resetEditAlertData}
                    setResetEditAlertData={setResetEditAlertData}
                    setCurrentAlertIdEdit={setCurrentAlertIdEdit}
                />
            </div>
    )
}

const ModalDownload = ({ toggleModalDownload, wantDownload, isDownloadingPublications, downloadPublicationsFileHandler, selectAllPagesChecked, deselectedIds, checkedPublications, queryString }) => {

    return (
        <Modal isOpen={wantDownload} toggleModalDownload={toggleModalDownload} keyboard={false} backdrop="static">
            <Box
                    id="modal-content"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: '100%',
                        maxWidth: '540px',
                        minWidth:  '540px',
                        minHeight: '574px',
                        bgcolor: 'white',
                        boxShadow: 24,
                        pt: 8,
                        zIndex: 1,
                        borderRadius: 4,
                        '@media (max-width: 600px)': {
                            maxWidth: '400px',
                            minWidth: '400',
                        }
                    }}
                >
                <div className={classes.headerModalWrapper}>
                    <div style={{ cursor: 'pointer' }} onClick={toggleModalDownload}>
                        <img src={CloseModal} />
                    </div>
                </div>
                <div className={classes.modalBodyWrapper}>
                    <div className={classes.imgIlustrateWrapper}>
                        <img className={classes.imgIlustrate} src={interrogaIlustra} />
                    </div>
                    <span className={classes.ModalText1}>¿Seguro que...</span>
                    <span className={classes.ModalText2}>Deseas solicitar la descarga de<br />
                        las publicaciones en un excel?</span>
                    <div style={{marginTop:"60px"}}  className={classes.dowloadButton} onClick={() => downloadPublicationsFileHandler()}>
                        {isDownloadingPublications ?
                            <CircularProgress size={30} style={{ color: "white" }} />
                            :
                            <span className={classes.dowloadButtonText}>Solicitar</span>
                        }
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default PublicationsList