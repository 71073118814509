import React from 'react';
import Box from '@mui/material/Box';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import classes from './PublicationsGraph.module.css';

// images
import EmptyPublicationsIcon from '../../../assets/icons/EmptyPublications.svg';


const toSpanishNumberFormat = (number) => {
  let numStr = number.toString().replace(/\D/g, '');
  let parts = numStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
  let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return formattedInteger + decimalPart;
}

// Series configuration with formatter
const series = [
  {
    type: 'line',
    dataKey: 'myProduct',
    color: '#000000',
    showMark: false,
  },
  {
    type: 'line',
    dataKey: 'rivalProduct',
    color: '#EB3223',
    showMark: false,
  },
  {
    type: 'bar',
    dataKey: 'sales',
    color: '#57C2BD', 
    yAxisId: 'rightAxis',
  },
];


const PublicationsGraph = ({ selectedPublication, selectedRowId, selectedColumn, graphData, tableData }) => {

  const isRivalsOnTable = selectedRowId && tableData?.length > 0;
  const noRivalGraphData = graphData.map(data => {
    // Create a copy of the object without the `rival` attribute
    const { rival, ...noRivalData } = data;
    return noRivalData;
  });
  
  // Find the statistics entry for the selectedRowId
  const selectedStats = isRivalsOnTable ? graphData.find(stat => stat.rival_id === selectedRowId) : noRivalGraphData[0];

  // Extract the relevant data for myProduct and rivalProduct
  const myProductData = selectedStats?.myPublication?.map(data => data[Object.keys(data)[0]]) || [];
  const rivalProductData = selectedStats?.rival?.map(data => data[Object.keys(data)[0]]) || [];

  // Create a dataset from the extracted data
  const dataset = (selectedPublication.statistics?.daily_sales_summary || []).map((entry, index) => {
    const [year, month, day] = entry.date.split('-');
    return {
      day: `${day}.${month}`, // Convert date to day-month format
      myProduct: myProductData[index] || 0, // Use real data for myProduct
      rivalProduct: rivalProductData[index] || 0, // Use real data for rivalProduct
      sales: entry.total_sales, // Real sales data
    };
  });


  const CustomItemTooltipContent = (props) => {
    const { axisData, series } = props;
    const { x } = axisData;
    const { index } = x;
  
    const myProductSeries = series.find(s => s.dataKey === 'myProduct');
    const rivalProductSeries = series.find(s => s.dataKey === 'rivalProduct');
    const salesSeries = series.find(s => s.dataKey === 'sales');
  
    const myProductSales = toSpanishNumberFormat(myProductSeries?.data[index]) ?? 'N/A';
    const rivalProductSales = toSpanishNumberFormat(rivalProductSeries?.data[index]) ?? 'N/A';
    const sales = toSpanishNumberFormat(salesSeries?.data[index]) ?? 'N/A';
  
    let tooltipContent = null;
  
    switch (selectedColumn) {
      case 'stock':
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y te quedaron <span style={{ fontWeight: 700 }}>{myProductSales} unidades</span>.</span>
            <span>Stock competencia: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
          </div>
        );
        break;
      case 'palabras':
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> con <span style={{ fontWeight: 700 }}>{myProductSales} palabras</span> en descripción.</span>
            <span>Palabras descripción competencia: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
          </div>
        );
        break;
      case 'imagenes':
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> con <span style={{ fontWeight: 700 }}>{myProductSales} imágenes</span>.</span>
            <span>Imágenes competencia: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
          </div>
        );
        break;
      case 'comentarios':
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y tuviste <span style={{ fontWeight: 700 }}>{myProductSales} comentarios</span>.</span>
            <span>Comentarios competencia: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
          </div>
        );
        break;
      case 'reviews':
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> y tuviste <span style={{ fontWeight: 700 }}>{myProductSales} reviews</span>.</span>
            <span>Reviews competencia: <span style={{ fontWeight: 700 }}>{rivalProductSales}</span></span>
          </div>
        );
        break;
      default: // 'precio'
        tooltipContent = (
          <div style={{ padding: '18px', backgroundColor: '#fff', border: '1px solid #ccc', display: 'flex', position: 'relative', flexDirection: 'column', borderRadius: '10px' }}>
            <span style={{ fontWeight: 700 }}>{x.value}</span>
            <span>Vendiste <span style={{ fontWeight: 700 }}>{sales} unidades</span> a un precio <span style={{ fontWeight: 700 }}>${myProductSales}</span></span>
            <span>Precio competencia: <span style={{ fontWeight: 700 }}>${rivalProductSales}</span></span>
          </div>
        );
        break;
    }
  
    return tooltipContent;
  };
  

  return (
    <div className={selectedStats?.myPublication ? classes.secondInnerGraphContainer : classes.secondInnerGraphContainer2}>
      {selectedStats?.myPublication ? 
        (
          <Box sx={{ width: '100%', height: '100%' }}>
            <ResponsiveChartContainer
              series={series}
              xAxis={[
                {
                  scaleType: 'band',
                  dataKey: 'day',
                  disableTicks: true,
                },
              ]}
              yAxis={[
                { id: 'leftAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL') }, // tickNumber: 4 to set the number of data on the axis
                { id: 'rightAxis', min: 0, disableTicks: true, valueFormatter: (value) => value.toLocaleString('es-CL')}, // to set invisible tickLabelStyle: { visibility: 'hidden' }
              ]}
              dataset={dataset}
              margin={{ left: 100 }}   
            >
              <ChartsGrid horizontal />
              <BarPlot borderRadius={10} />
              <LinePlot />
              <MarkPlot />
              <ChartsXAxis />
              <ChartsYAxis axisId="leftAxis"  position="left" />
              <ChartsYAxis axisId="rightAxis" position="right"  />
              <ChartsTooltip 
                trigger="axis" 
                slots={{
                  axisContent: CustomItemTooltipContent
                }}
              />
            </ResponsiveChartContainer>
          </Box>
        ) 
        : 
        (
          <div className={classes.dataNotFound}>
            <div className={classes.noPublicationsContainer}>
              <img
                  className={classes.publicationNotFoundIcon}
                  src={EmptyPublicationsIcon}
                  alt="empty-publications-icon"
              />
              <div className={classes.publicationNotFoundtextContainer}>
                  <span>Upsss... Por aquí no</span>
                  <span>encuentro información...</span>
                  <span className={classes.tryLaterText}>¡Intentalo más tarde!</span>
              </div>
            </div>
          </div>
        )
      }      
    </div>
  );
};

export default PublicationsGraph;
