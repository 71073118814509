import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import { Modal } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchPublications from './Components/SearchPublications';
import classes from './Competitiveness.module.css';
import AlertSeller from '../../components/UI/AlertSeller';
import DownloadsCenter from '../../components/DownloadsCenter/DownloadsCenter';

// images
import ArrowIcon from '../../assets/img/icons/unfold-to-right.svg';
import DownloadButton from '../../assets/img/icons/download-button-icon.svg';
import SuggestionsButton from '../../assets/img/icons/spark-button-icon.svg';
import interrogaIlustra from "../../assets/img/interroga.png"
import Close from "../../assets/img/icons/close_black.png"
import confirmation from '../../assets/img/onboarding/Validacion.png'

// Services
import { getPublicationsAnalyticsExcel } from 'services/publications';
import { deletePublicationCompetition } from 'services/publications';

const Competitiveness = () => {
    const [searchView, setSearchView] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [copyResultText, setCopyResultText] = useState('');
    const [copyCreationResult, setCopyCreationResult] = useState(false);
    const [deleteAlertText, setDeleteAlertText] = useState('');
    const [deleteRivalAlert, setDeleteRivalAlert] = useState(false);
    const [wantDownload, setWantDownload] = useState(false);
    const [selectedPublication, setSelectedPublication] = useState(null);
    const [loadingCompetition, setLoadingCompetition] = useState(false);
    const [isDownloadingRivals, setIsDownloadingRivals] = useState(false);
    const [wantDelete, setWantDelete] = useState(false);
    const [isDeletingRivals, setIsDeletingRivals] = useState(false);
    const [rivalId, setRivalId] = useState('');
    const [selectedRival, setSelectedRival] = useState(null);    
    const [actionsForDownloads, setActionsForDownloads] = useState({
        refreshDownloads: false,
        unfoldDownloadCenter: false,
    });

    const toggleModalDownload = () => setWantDownload(!wantDownload); 
    const toggleModalDelete = () => setWantDelete(!wantDelete); 

    const [alertText, setAlertText] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertTime, setAlertTime] = useState(4500);

    useEffect(() => {
        if (copyCreationResult) {
            setAlertTime(3000);
            setAlertType("success");
            handleAlertCopy(copyResultText);           
        }else{            
        }
    }, [copyCreationResult]);

    useEffect(() => {
        if (deleteRivalAlert) {
            setAlertTime(4500);
            setAlertType("success");
            handleAlertDelete(deleteAlertText);           
        }else{            
        }
    }, [deleteRivalAlert]);

    const handleAlertCopy = (alertText) => { 
        setShowAlert(true);
        setAlertText(alertText);
        setCopyCreationResult(false);
    }

    const handleAlertDelete = (alertText) => { 
        setShowAlert(true);
        setAlertText(alertText);
        setDeleteRivalAlert(false);
    }

    const handleAlertError = (alertText) => { 
        setShowAlert(true);
        setAlertText(alertText);
        setCopyCreationResult(false);
    }

    const handleReturnView = () => {        
        setSearchView(true);
        setSelectedPublication(null);
    }

    const handleDownloadData = async () => {
        try {
            setIsDownloadingRivals(true);
            const response = await getPublicationsAnalyticsExcel(`?id=${selectedPublication.ean}&user=${localStorage.getItem("name")}`);            
            setIsDownloadingRivals(false);
            const url = response?.data?.url;

            
            if (url) {                
                setActionsForDownloads({
                    ...actionsForDownloads,
                    unfoldDownloadCenter: true,
                    refreshDownloads: true
                })
            } 
        } catch (error) {
            setAlertTime(4500);
            setAlertType("error");
            handleAlertError("Error en el cambio... ¡Inténtalo nuevamente!");
        } finally {
            toggleModalDownload();
        }
    }
    
    const handleDeleteRival = async () => {
        try {
            setIsDeletingRivals(true);
            const response = await deletePublicationCompetition(`?url_id=${rivalId}`);
            setIsDeletingRivals(false);

            if(response?.data){
                setDeleteAlertText('Listo... nunca más verás esa publicación');
                setDeleteRivalAlert(true); 
                setLoadingCompetition(true);
            }
           
        } catch (error) {
            setAlertTime(4500);
            setAlertType("error");
            handleAlertError("Error en el cambio... ¡Inténtalo nuevamente!");
        } finally {            
            toggleModalDelete();
        }
    }

    return (
        <React.Fragment>
            <div>
                {showAlert && <AlertSeller showAlert={showAlert} text={alertText} setShowAlert={setShowAlert} time={alertTime} type={alertType}/>}
                <div className={classes.wrapperTitle}> 
                    { !searchView ? 
                        (
                            <>
                                <span className={classes.title2}>Competitividad / {selectedPublication?.sku}</span>
                                <div className={classes.titleContainer}>
                                    <div className={classes.arrowIconContainer} onClick={handleReturnView} >
                                        <img className={classes.arrowIcon} src={ArrowIcon} alt='Volver'/>
                                    </div> 
                                    <div className={classes.title}>
                                        <span className={classes.titleText}>{selectedPublication?.name ? selectedPublication?.name : 'Competitividad'}</span>
                                    </div>                                                                       
                                    {loadingCompetition ? 
                                        (
                                            <div className={classes.topButtonsContainer}>
                                                <div className={classes.downloadButtonWrapper}>
                                                    <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35}} />
                                                </div>                    
                                                {/*<div className={classes.suggestionButtonWrapper}>
                                                    <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)",  opacity: 0.35}} />
                                                </div>*/}
                                            </div>
                                        ) : 
                                        (
                                            <div className={classes.topButtonsContainer}>
                                                <div className={classes.downloadButtonWrapper}>
                                                    <img className={classes.downloadIcon} src={DownloadButton} alt="Download Icon" onClick={() => toggleModalDownload()} />
                                                </div>                    
                                                {/*<div className={classes.suggestionButtonWrapper}>
                                                    <img className={classes.suggestionIcon} src={SuggestionsButton} alt="Suggestion Icon" />
                                                </div>*/}
                                            </div>
                                        )
                                    }
                                </div>                                
                            </>
                        ) : 
                        (
                            (
                                <>         
                                    <span className={classes.title2}> </span>                       
                                    <div className={classes.titleContainer}>
                                        <img className={classes.arrowIconHidden} src={ArrowIcon} alt='Volver' />
                                        <span className={classes.title}>Competitividad</span>
                                    </div>
                                </>
                            )
                        )
                    } 
                </div>
                <div className={classes.wrapper}>
                    <SearchPublications
                    searchView={searchView}
                    setSearchView={setSearchView}
                    setCopyResultText={setCopyResultText}
                    setCopyCreationResult={setCopyCreationResult} 
                    selectedPublication={selectedPublication}
                    setSelectedPublication={setSelectedPublication}
                    loadingCompetition={loadingCompetition}
                    setLoadingCompetition={setLoadingCompetition}
                    setRivalId={setRivalId}
                    setWantDelete={setWantDelete}                    
                    setSelectedRival={setSelectedRival}
                    />
                    {wantDownload && (
                        <ModalDownload
                            toggleModalDownload={toggleModalDownload}
                            wantDownload={wantDownload}
                            handleDownloadData={handleDownloadData}
                            isDownloadingRivals={isDownloadingRivals}
                        ></ModalDownload>
                    )}
                    {wantDelete && (
                        <ModalDeleteRival
                            toggleModalDelete={toggleModalDelete}
                            wantDelete={wantDelete}
                            handleDeleteRival={handleDeleteRival}
                            isDeletingRivals={isDeletingRivals}
                        ></ModalDeleteRival>
                    )}
                </div>
                <DownloadsCenter
                    actionsForDownloads={actionsForDownloads}
                    setActionsForDownloads={setActionsForDownloads}
                />             
            </div>
        </React.Fragment>
    )
}

const ModalDownload = ({ toggleModalDownload, wantDownload, handleDownloadData, isDownloadingRivals }) => {
    
    return (
        <Modal className={classes.modalWrapperModal} isOpen={wantDownload} toggle={toggleModalDownload} size="md" keyboard={true}>
            <div className={classes.headerModalWrapper}>
                <div className={classes.xButtonWrapper} onClick={toggleModalDownload}>
                    <img className={classes.xButton} src={Close} alt="Close" />
                </div>
            </div>
            <div className={classes.modalBodyWrapper}>
                <div className={classes.imgIlustrateWrapper}>
                    <img className={classes.imgIlustrate} src={interrogaIlustra} alt="Illustration" />
                </div>
                <span className={classes.ModalText1}>¿Seguro que...</span>
                <span className={classes.ModalText2}>
                    Deseas solicitar la descarga de<br />
                    un reporte de competitividad?
                </span>
                {isDownloadingRivals ? 
                    (
                        <div className={classes.LoadingButtonContainer} >
                            <span className={classes.dowloadButtonText}>Cargando</span>
                        </div>
                    )
                    :
                    (
                        <div className={classes.dowloadButton} onClick={handleDownloadData}>
                            <span className={classes.dowloadButtonText}>Solicitar</span>
                        </div>
                    )
                }                
            </div>
        </Modal>
    );
}

const ModalDeleteRival = ({ toggleModalDelete, wantDelete, handleDeleteRival, isDeletingRivals }) => {
    
    return (
        <Modal className={classes.modalWrapperModal} isOpen={wantDelete} toggle={toggleModalDelete} size="md" keyboard={true}>
            <div className={classes.headerModalWrapper}>
                <div className={classes.xButtonWrapper} onClick={toggleModalDelete}>
                    <img className={classes.xButton} src={Close} alt="Close" />
                </div>
            </div>
            <div className={classes.modalBodyWrapper}>
                <div className={classes.imgIlustrateWrapper}>
                    <img className={classes.imgIlustrate} src={confirmation} alt="Illustration" />
                </div>
                <span className={classes.ModalText1}>¿Seguro que...</span>
                <span className={classes.ModalText2}>
                    Quieres reportar esta<br />
                    publicación de la competencia<br />
                    para no verla más?
                </span>
                <div className={classes.buttonContainerFooter}>
                    {isDeletingRivals ? (
                        <>
                            <div className={classes.LoadingButtonContainer}>
                                <span className={classes.dowloadButtonText}>Cargando</span>
                            </div>
                            <div className={classes.cancelButtonHidden} onClick={toggleModalDelete}>
                                <span className={classes.cancelarButtonText}>Cancelar</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.dowloadButton} onClick={handleDeleteRival}>
                                <span className={classes.dowloadButtonText}>Reportar</span>
                            </div>
                            <div className={classes.cancelButton} onClick={toggleModalDelete}>
                                <span className={classes.cancelarButtonText}>Cancelar</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default Competitiveness