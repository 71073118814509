import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'reactstrap';
import Box from '@mui/material/Box';
import classes from './AlertsCenter.module.css';
import CustomModal from '../UI/CustomModal';
import DatePicker from '../UI/DatePicker';
import AlertTooltip from '../../views/publications/Components/UI/AlertTooltip';
import EmailCheckbox from '../UI/EmailCheckbox';
import TermAndConditions from '../UI/TermAndConditions';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Swal from 'sweetalert2'

import firstModalStyles from '../PublicationAlertModals/FirstAlertModal.module.css';
import secondModalStyles from '../PublicationAlertModals/SecondAlertModal.module.css';
import thirdModalStyles from '../PublicationAlertModals/ThirdAlertModal.module.css';
import addEmailModalStyles from '../PublicationAlertModals/AddEmailModal.module.css';
import fourthModalStyles from '../PublicationAlertModals/FourthAlertModal.module.css';
import FirstAlertModalLoader from '../../views/publications/Components/FirstAlertModalLoader';
import AlertTypeCard from '../../views/publications/Components/AlertTypeCard';

import Plus from "../../assets/img/icons/plus-icon.svg";
import unfoldToRigthIcon from "../../assets/img/icons/unfold-to-right.svg"
import inforFortooltipIcon from "../../assets/img/icons/info-tooltip.svg"
import CalendarPicker from "../../assets/img/icons/calendar-picker.svg";
import Close from "../../assets/img/icons/CloseModal.svg";
import Delete from "../../assets/img/icons/iconTrash.svg";
import Interrogation from "../../assets/img/icons/interrogation-plant.png"
import PlusButton from "../../assets/img/icons/Group380Icon.svg"
import confirmation from '../../assets/img/onboarding/Validacion.png'
import noPublicationsInAlertImg from "../../assets/img/searches/no-results-products.svg"

//Services
import { getAlertTypes, updateAlert } from '../../services/alerts';
import { getPublications } from '../../services/publications'

const AlertsCenter = ({ 
    myAlertsCounter, 
    alerts, 
    allMyAlerts, 
    filter, 
    selectAllPagesChecked, 
    clientIds, 
    deselectedIds, 
    checkedPublications, 
    setAlertCreationResult,
    setAlertCreationResultText,
    setGlobalError,
    isEditView,
    setViewTitle,
    setIsEditView,
    getPublicationsToAdd, 
    parentFunctionToCallChild, 
    setParentFunctionToCallChild, 
    isButtonHidden, 
    toggleCreateAlertModal, 
    resetEditAlertData, 
    setResetEditAlertData, 
    setCurrentAlertIdEdit }) => {

    const [isHidden, setisHidden] = useState(true);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [alertToUpdate, setAlertToUpdate] = useState(null);
    const [alertTypes, setAlertTypes] = useState([]);
    const [loadingAlertTypes, setLoadingAlertTypes] = useState(true);
    const [firstModal, setFirstModal] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [checkedAlertTypes, setCheckedAlertTypes] = useState([]);
    const [thirdModal, setThirdModal] = useState(false);
    const [addEmailModal, setAddEmailModal] = useState(false);
    const [fourthModal, setFourthModal] = useState(false);    
    const [fifthModal, setFifthModal] = useState(false); 
    const [sixthModal, setSixthModal] = useState(false); 
    const [activeDays, setActiveDays] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [emails, setEmails] = useState([]);
    const [inputEmail, setInputEmail] = useState("");    
    const [checkedEmails, setCheckedEmails] = useState([]); 
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [inputEditAlertName, setInputEditAlertName] = useState("");
    const [newAlertName, setNewAlertName] = useState(""); 
    const [hasDuplicateNameError, setHasDuplicateNameError] = useState(false);
    const [loadingAlert, setLoadingAlert] = useState(false);
    const [firstClick, setFirstClick] = useState(true);
    const [originalAlertName, setOriginalAlertName] = useState("");
    const [selectedAlertId, setSelectedAlertId] = useState("");
    const [alertTypeNames, setAlertTypeNames] = useState("");
    const [emailString, setEmailString] = useState("");
    const [isPaginationActive, setIsPaginationActive] = useState(false);
    const [publicationSize, setPublicationSize] = useState(100);
    const [paginationPage, setPaginationPage] = useState(1);
    const [finalPaginationPage, setFinalPaginationPage] = useState(1);
    const [publications, setPublications] = useState([]);
    const [loadingPublications, setLoadingPublications] = useState(false);
    const [callPublications, setCallPublications] = useState(false);
    const [query, setQuery] = useState('');
    const [noPublications, setNoPublications] = useState([]);

    const toggleFirstModal = () => setFirstModal(!firstModal);
    const toggleSecondModal = () => setSecondModal(!secondModal);
    const toggleThirdModal = () => setThirdModal(!thirdModal);
    const toggleAddEmailModal = () => setAddEmailModal(!addEmailModal);
    const toggleFourthModal = () => setFourthModal(!fourthModal);
    const toggleFifthModal = () => setFifthModal(!fifthModal);
    const toggleSixthModal = () => setSixthModal(!sixthModal);

    const optionsMenuRef = useRef(null);

    let height = Math.min(alerts.length * 60, 300) + 'px';

    const days = [
        { key: 1, label: 'Lun', fullLabel: 'Lunes' },
        { key: 2, label: 'Mar', fullLabel: 'Martes' },
        { key: 3, label: 'Mie', fullLabel: 'Miércoles' },
        { key: 4, label: 'Jue', fullLabel: 'Jueves' },
        { key: 5, label: 'Vie', fullLabel: 'Viernes' },
    ];

    const tooltipContent = `<div style="width: 330px;">
        <div style="width: 290px; position: relative; font-size: 15px; text-align: left; display: inline-block; color: #000; font-family: Montserrat; margin: 2px 21px;">
        <p style="margin: 0;">
            <span>
                <span style="font-weight: 300; font-family: Montserrat;">
                    Aquí podrás gestionar todas tus alertas ya creadas.
                </span>
                <br>
                <br>
                <span style="font-weight: 300; font-family: Montserrat;">
                    Recuerda que...
                </span>
                <br>
                <span style="font-weight: 500;">
                    ¡Para crear una alerta primero debes seleccionar al menos 1 publicación!
                </span>
            </span>
        </p>
        </div>
    </div>`;

    const resetComponentData = () => {
        setSelectedAlert(null);
        setShowOptions(false);
        setAlertToUpdate(null);
        setLoadingAlertTypes(true);
        setCheckedAlertTypes([]);
        setActiveDays({});
        setSelectedDate(null);
        setShowDatePicker(false);
        setStartDate(null);
        setEndDate(null);
        setIsButtonEnabled(false);
        setEmails([]);
        setInputEmail("");    
        setCheckedEmails([]); 
        setIsCheckedTerms(false);
        setInputEditAlertName("");
        setNewAlertName(""); 
        setHasDuplicateNameError(false);
        setLoadingAlert(false);
        setFirstClick(true);
        setOriginalAlertName("");
        setSelectedAlertId("");
        setAlertTypeNames("");
        setEmailString("");
        setIsPaginationActive(false);
        setPublicationSize(100);
        setPaginationPage(1);
        setFinalPaginationPage(1);
        setPublications([]);
        setLoadingPublications(false);
        setCallPublications(false);
        setQuery('');
        setNoPublications([]);
        setResetEditAlertData(false);
    }

    const closeFirstModalCloseButton = async () => {
        const hasChanges = !areArraysEqual(checkedAlertTypes, alertToUpdate.alert_type_ids);
    
        if (hasChanges) {
            const modalResult = await closeConfirmationModal();
            
            if (modalResult) {
                setFirstModal(false);
                setCheckedAlertTypes([]);
            }
        } else {
            setFirstModal(false);
            setCheckedAlertTypes([]);
        }
    }
    
    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((value, index) => value === arr2[index]);
    }

    const areFrequenciesEqual = (activeDays, alertFrequency) => {
        const activeDaysArray = Object.entries(activeDays)
            .filter(([_, value]) => value)
            .map(([key, _]) => parseInt(key));

        
        if (activeDaysArray.length !== alertFrequency.length) return false;
        
        return activeDaysArray.every(day => alertFrequency.includes(day));
    };

    const areDatesEqual = (date1, date2) => {
        if (!date1 && !date2) return true;
        if (!date1 || !date2) return false;
        const date1Str = typeof date1 === 'string' ? date1.split('T')[0] : new Date(date1).toISOString().split('T')[0];
        const date2Str = typeof date2 === 'string' ? date2.split('T')[0] : new Date(date2).toISOString().split('T')[0];

        return date1Str === date2Str;
    };

    const closeSecondModalCloseButton = async () => {
        const hasChanges = 
            !areFrequenciesEqual(activeDays, alertToUpdate.alert_frequency) ||
            !areDatesEqual(startDate, alertToUpdate.alert_start_date) ||
            !areDatesEqual(endDate, alertToUpdate.alert_end_date);

        if (hasChanges) {
            const modalResult = await closeConfirmationModal();
            
            if (modalResult) {
                setSecondModal(false);
                resetFormSecondModal();
            }
        } else {            
            setSecondModal(false);
            resetFormSecondModal();
        }
    };
    
    const resetFormSecondModal = () => {
        setActiveDays({});
        setSelectedDate(null);
        setShowDatePicker(false);
        setStartDate(null);
        setEndDate(null);
        setFirstClick(true);
    };

    const areEmailArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();
        return sortedArr1.every((value, index) => value === sortedArr2[index]);
    };

    const closeThirdModalCloseButton = async () => {
        const hasChanges = !areEmailArraysEqual(checkedEmails, alertToUpdate?.alert_recipients);

        if (hasChanges) {
            const modalResult = await closeConfirmationModal();
            
            if (modalResult) {
                setThirdModal(false);
                resetThirdModalForm();
            }
        } else {
            setThirdModal(false);
            resetThirdModalForm();
        }
    };

    const resetThirdModalForm = () => {
        setEmails([]);
        setCheckedEmails([]);
        setIsCheckedTerms(false);
    };

    const closeAddEmailModalCloseButton = async () => {
        if(inputEmail?.length > 0){
            const modalResult = await closeConfirmationModal();    
                   
            if(modalResult){
                setAddEmailModal(false);
                setInputEmail(""); 
            } 
        }else{
            setAddEmailModal(false);
            setInputEmail(""); 
        }                       
    }

    const closeFourthModalCloseButton = async () => {
        if(originalAlertName !== inputEditAlertName){
            const modalResult = await closeConfirmationModal();    
                 
            if(modalResult){
                setFourthModal(false);  
                setInputEditAlertName("");
                setNewAlertName(""); 
            }  
        }else{
            setFourthModal(false);  
            setInputEditAlertName("");
            setNewAlertName(""); 
        }                       
    }

    const closeFirstModal = async (warningAlert) => {
        const modalResult = !warningAlert ? true : await closeConfirmationModal(); 
                 
        if(modalResult){
            setFirstModal(false);
            setCheckedAlertTypes([]);
        }              
    }

    const closeSecondModal = async (warningAlert) => {
        const modalResult = !warningAlert ? true : await closeConfirmationModal();    
                 
        if(modalResult){
            setSecondModal(false);
            setActiveDays({});
            setSelectedDate(null);
            setShowDatePicker(false);
            setStartDate(null);
            setEndDate(null);
            setFirstClick(true);
        }              
    }

    const closeThirdModal = async (warningAlert) => {
        const modalResult = !warningAlert ? true : await closeConfirmationModal();  
            
        if(modalResult){
            setThirdModal(false);
            setEmails([]);
            setCheckedEmails([]); 
            setIsCheckedTerms(false);
        }        
    }

    const closeAddEmailModal = async (warningAlert) => {
        const modalResult = !warningAlert ? true : await closeConfirmationModal();    
                   
        if(modalResult){
            setAddEmailModal(false);
            setInputEmail(""); 
        }                
    }

    const closeFourthModal = async (warningAlert) => {
        const modalResult = !warningAlert ? true : await closeConfirmationModal();    
                 
        if(modalResult){
            setFourthModal(false);  
            setInputEditAlertName("");
            setNewAlertName(""); 
        }                   
    }

    const closeFifthModal = async () => {
        if(loadingPublications){
            const modalResult = await closeConfirmationModal();    
                   
            if(modalResult){
                setFifthModal(false);
                setActiveDays({});
                setStartDate(null);
                setEndDate(null);
                setAlertTypeNames("");
                setEmailString("");
                setIsPaginationActive(false);
                setPublicationSize(100);
                setPaginationPage(1);
                setFinalPaginationPage(1);
                setPublications([]);
                setLoadingPublications(false);
                setCallPublications(false);
                setQuery('');
                setNoPublications([]); 
            }  
        } else{
            setFifthModal(false);
            setActiveDays({});
            setStartDate(null);
            setEndDate(null);
            setAlertTypeNames("");
            setEmailString("");
            setIsPaginationActive(false);
            setPublicationSize(100);
            setPaginationPage(1);
            setFinalPaginationPage(1);
            setPublications([]);
            setLoadingPublications(false);
            setCallPublications(false);
            setQuery('');
            setNoPublications([]); 
        }             
    }

    const closeSixthModal = () => {
        setSixthModal(false);
    }

    const closeSomeModals = (warningAlert) => {
        closeFirstModal(warningAlert);
        closeSecondModal(warningAlert);
        closeThirdModal(warningAlert);
        closeAddEmailModal(warningAlert);
        closeFourthModal(warningAlert);
        closeFifthModal();
    }

    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleAddEmail = (emailAddress) => {
        setEmails([...emails, emailAddress]);        
        handleAddEmailModalReturn();
    };

    const handleAlertName = (alertName) => {
        setNewAlertName(alertName);
        handleSaveChanges();
    };

    const toggleDatePicker = () => {
        setShowDatePicker(prev => !prev);
    };

    const toggleDay = (day) => {
        if(!startDate){
            setStartDate(new Date());
        }        
        setActiveDays((prev) => ({ ...prev, [day]: !prev[day] }));        
    };        

    const handleAddEmailModalReturn = () => {
        toggleThirdModal();
        setInputEmail("");
        toggleAddEmailModal();
    };

    const handleClickUnfold = () =>{
        handleCloseOptions();
        setisHidden((prevValue) => !prevValue)
    }

    const toSpanishNumberFormat = (number) => {
        let numStr = number.toString().replace(/\D/g, '');
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
        let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return formattedInteger + decimalPart;
    }
  
    const handleSelectAlert = (alert) => {
        setSelectedAlertId(alert.alert_id);
        setSelectedAlert(alert);
        setShowOptions(!showOptions);
    };

    const handleCloseOptions = () => {
        setShowOptions(false);        
    };

    const handleEditAlertType = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);
        setAlertToUpdate(currentAlert);
        setCheckedAlertTypes(currentAlert.alert_type_ids);
        setFirstModal(true);
    }

    const formatDateAndFrequency = (currentAlert) => {
        const transformToDate = (dateString) => {
            const [year, month, day] = dateString.split('-');
            return new Date(year, month - 1, day, 0, 0, 0); // default time: 00:00:00
        };

        const transformToActiveDays = (frequencyArray) => {
            return frequencyArray.reduce((acc, day) => {
                acc[day] = true;
                return acc;
            }, {});
        };

        setActiveDays(transformToActiveDays(currentAlert.alert_frequency));
        setStartDate(transformToDate(currentAlert.alert_start_date));
        if(currentAlert.alert_end_date){
            setEndDate(transformToDate(currentAlert.alert_end_date));
        } 
    }

    const handleEditAlertFrequency = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);
        setAlertToUpdate(currentAlert);
        formatDateAndFrequency(currentAlert);        
        setSecondModal(true);
    }

    const handleEditAlertEmails = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);
        const localStorageEmail = localStorage.getItem("name");
    
        setAlertToUpdate(currentAlert);
    
        setEmails(prevEmails => {
            const updatedEmails = [...prevEmails, ...currentAlert?.alert_recipients || []];

            if (!updatedEmails.includes(localStorageEmail)) {
                updatedEmails.push(localStorageEmail);
            }
    
            return updatedEmails;
        });
    
        setCheckedEmails(currentAlert?.alert_recipients);
        setThirdModal(true);
    }
    

    const handleEditAlertName = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);
        setAlertToUpdate(currentAlert);
        setOriginalAlertName(currentAlert.alert_name);
        setInputEditAlertName(currentAlert.alert_name);
        setFourthModal(true);
    }

    const handelDeleteAlert = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);
        setAlertToUpdate(currentAlert);
        setSixthModal(true);
    }

    const handelDeleteAlertRequest = () => {
        setLoadingAlert(true);
        handleAlertToUpdate(alertToUpdate, "", true);
    }

    const handleAddPublications = (alert) => {
        const currentAlert = allMyAlerts.find(item => item.id === alert.alert_id);                   

        setAlertToUpdate(currentAlert);             
        formatDateAndFrequency(currentAlert);     
        setEmailString(currentAlert?.alert_recipients.join('; '));  
        setIsPaginationActive(currentAlert?.alert_publications?.length > 100);
        setFinalPaginationPage(Math.ceil(currentAlert?.alert_publications?.length / 100));
      
        setQuery(`?alert_id=${selectedAlert.alert_id}&alert_publications=ONLY_PUBLICATIONS_IN_ALERT&page_size=100&page=1`);
        setFifthModal(true);
        setCallPublications(true);
    }

    const handlePreviousPage = () => {
        const pages = paginationPage - 1
        setPaginationPage(pages);
        setFinalPaginationPage(Math.ceil(selectedAlert?.alert_publications?.length / 100));

        setQuery(`?alert_id=${selectedAlert.alert_id}&alert_publications=ONLY_PUBLICATIONS_IN_ALERT&page_size=100&page=${pages}`);
        setCallPublications(true);
    }

    const handleNextPage = () => {
        const pages = paginationPage + 1
        setPaginationPage(pages);
        setFinalPaginationPage(Math.ceil(selectedAlert?.alert_publications?.length / 100));
       
        setQuery(`?alert_id=${selectedAlert.alert_id}&alert_publications=ONLY_PUBLICATIONS_IN_ALERT&page_size=100&page=${pages}`);
        setCallPublications(true);
    }

    const handleDeletePublication = (id_publication) => {
        setLoadingPublications(true);
        setNoPublications(id_publication);
        handleAlertToUpdate(alertToUpdate, id_publication, true);
    }

    const getPublicationsForAlert = async () => {
        try {      
            setPublications([]); 
            setLoadingPublications(true);             
            setCallPublications(false);
            const response = await getPublications(query); 
            
            setPublications(response?.data?.publications);
            setLoadingPublications(false);                 

        } catch (error) {
            setGlobalError(error.message);            
        } 
    }

    const handleNewPublications = async () => {        
        setViewTitle(alertToUpdate?.alert_name ?? '');
        setIsEditView(true);
        toggleFifthModal();

        await getPublicationsToAdd(selectedAlert.alert_id, publications);
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }; 

    const handleAlertToUpdate = (alert, noPublication = "", deletePublication=false) => {
        const publications = !selectAllPagesChecked && isEditView ? checkedPublications : [];
        const no_Publications = selectAllPagesChecked && isEditView ? deselectedIds : [];
        let currentFilter = '';
        
        if (selectAllPagesChecked) {
            currentFilter = filter === '?' ? `client_id=${clientIds.join(',')}` : filter;
        }

        const formatDate = date => date ? date.toISOString().slice(0, 10) : '';
    
        const selectedDays = Object.keys(activeDays)
            .filter(key => activeDays[key])
            .map(key => {
                const day = days.find(day => day.key.toString() === key);
                return day ? day.key : '';
            });

        const parseFilterString = (filterString) => {
            const queryString = filterString.startsWith('?') ? filterString.slice(1) : filterString;
            const queryParams = new URLSearchParams(queryString);
            const filterObject = {};
            const keyMapping = {
                store_id: 'client_id',
                country_id: 'country.id',
                category_name: 'category',
                brand_name: 'brand'
            };
    
            for (const [key, value] of queryParams.entries()) {
                const mappedKey = keyMapping[key] || key;
                if (mappedKey === 'client_id') {
                    filterObject[mappedKey] = { $in: value.split(',').map(Number) };
                } else if (mappedKey === 'country.id') {
                    filterObject[mappedKey] = isNaN(value) ? value : Number(value);
                } else {
                    if (value.includes(',')) {
                        filterObject[mappedKey] = value.split(',').map(Number);
                    } else {
                        filterObject[mappedKey] = isNaN(value) ? value : Number(value);
                    }
                }
            }
            return filterObject;
        };

        const newPublications = selectedAlert.alert_publications.filter(publication => publication !== noPublication);

        const finalAlertData = {//ARREGLAR ESTO!!!!!!!
            alert: {
                id: selectedAlertId,
                alert_name: fourthModal ? inputEditAlertName : alert.alert_name,
                alert_type_ids: firstModal ? checkedAlertTypes : alert.alert_type_ids,
                alert_frequency: secondModal ? selectedDays : alert.alert_frequency,
                alert_start_date: secondModal ? formatDate(startDate) : alert.alert_start_date,
                alert_end_date: secondModal ? formatDate(endDate) : alert.alert_end_date,
                alert_recipients: thirdModal ? checkedEmails : alert.alert_recipients,
                alert_creator: alert.alert_creator,
                alert_creation_date: alert.alert_creation_date,
                alert_update_date: new Date().toISOString(),
                enabled: sixthModal ? false : alert.enabled
            },
            filter: parseFilterString(currentFilter),
            publications: fifthModal ? newPublications : isEditView ? publications.concat(newPublications) : selectedAlert?.alert_publications,
            no_publications: fifthModal ? noPublication : no_Publications,// para borrar!!!!!!!!!!!!!
        };

        handlePostAlert(finalAlertData, deletePublication, noPublication);        
    }    

    const handlePostAlert = async (finalAlertData, deletePublication = false, noPublication = "") => {
        let correctResponse = false;
        let response = null;
        try {           
            response = await updateAlert(finalAlertData);         
            
            if (response.data.data.has_duplicate_name) {
                setHasDuplicateNameError(true);                
            } else {
                setHasDuplicateNameError(false);
            }

            correctResponse = response?.data?.data?.code !== 500 ;

        } catch (error) {
            setGlobalError(error.message);
            setHasDuplicateNameError(false);
        } finally {
            setLoadingAlert(false);
            setParentFunctionToCallChild(false);
            if(correctResponse && !response.data.data.has_duplicate_name){   
                if(deletePublication){ //deleteAlertModal
                    setAlertCreationResultText("Deshacerse de las cosas a veces es bueno :)"); 
                    setAlertCreationResult(true);                    
                    updateAlertDataAfterDelete(noPublication);                   
                    getPublicationsForAlert();
                    closeSixthModal();
                } else{
                    setAlertCreationResultText("¡Cambio realizado con éxito!");  
                    setCurrentAlertIdEdit(selectedAlertId);                                         
                    setAlertCreationResult(true);
                    closeSomeModals(false);
                }
                
            }else if(!correctResponse && !response.data.data.has_duplicate_name){
                setAlertCreationResultText("Error en el cambio... ¡Intentalo nuevamente!"); 
                setAlertCreationResult(false);
            }
        }
    };

    const updateAlertDataAfterDelete = (noPublication) => {

        setSelectedAlert(prevSelectedAlert => ({
            ...prevSelectedAlert,
            alert_publications: prevSelectedAlert.alert_publications.filter(pub => pub !== noPublication)
        }));
    
        setAlertToUpdate(prevAlertToUpdate => ({
            ...prevAlertToUpdate,
            alert_publications: prevAlertToUpdate.alert_publications.filter(pub => pub.id_publication !== noPublication)
        }));
    
        setPublications(prevPublications => prevPublications.filter(pub => pub.id_publication !== noPublication));        
         
    }

    const handleSaveChanges = () => { 
        setLoadingAlert(true);
        handleAlertToUpdate(alertToUpdate);
    }

    const getAlertTypesData = async () => {
        try {
            setLoadingAlertTypes(true);
            const response = await getAlertTypes();
            setAlertTypes(response.data)
        } catch (error) {
            setGlobalError(error.message);
        } finally {
            setLoadingAlertTypes(false);
        }
    };

    useEffect(() => {
        if (firstModal && loadingAlertTypes) {
            getAlertTypesData();
        }
    }, [firstModal, loadingAlertTypes]);

    useEffect(() => {
        if (callPublications && !loadingPublications) {
            getPublicationsForAlert();
        }
    }, [callPublications, loadingPublications]);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (optionsMenuRef.current && !optionsMenuRef.current.contains(event.target)) {
            handleCloseOptions();
          }
        };
    
        if (showOptions) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [showOptions]);

      useEffect(() => {
        const activeDaysCount = Object.values(activeDays).filter(Boolean).length;
        setIsButtonEnabled(activeDaysCount > 0);
        if(activeDaysCount < 1 && firstClick && !endDate){
            setStartDate(null);
        }
    }, [activeDays, startDate]);

    const formatDate = (date, monthFormat = 'long') => {
        const options = { day: 'numeric', month: monthFormat, year: 'numeric' };
        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };

    const toggleCreateAlertModalCaller = (event) => {
        event.stopPropagation();
        toggleCreateAlertModal();        
    };
    
    const formatDateRange = (start, end) => {
        if (start && end) {
            const sameMonthYear = start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear();
            const sameYear = start.getFullYear() === end.getFullYear();
    
            if (sameMonthYear) {
                const endDateFormatted = formatDate(end, 'long');
                return `${start.getDate()} - ${endDateFormatted}`;
            } else if (sameYear) {
                const startDateFormatted = formatDate(start, 'long');
                const endDateDayMonth = new Intl.DateTimeFormat('es-ES', { day: 'numeric', month: 'long' }).format(end);
                return `${startDateFormatted} - ${endDateDayMonth} de ${end.getFullYear()}`;
            } else {
                const startDateFormatted = formatDate(start, 'long');
                const endDateFormatted = formatDate(end, 'long');
                return `${startDateFormatted} - ${endDateFormatted}`;
            }
        } else if (start) {
            return formatDate(start, 'long');
        }
        return "Selecciona una fecha";
    };
    
    const handleInputEmailChange = (event) => {
        setInputEmail(event.target.value);
    };

    const handleInputAlertNameChange = (event) => {
        setInputEditAlertName(event.target.value);    
        setHasDuplicateNameError(false); 
    };      
    
    const closeConfirmationModal = async () => {
        return new Promise(function (resolve) {
          Swal.fire({
            title: '¿ Seguro que...',
            html: "quieres salir sin nada de <br> lo que avanzaste se guarde?",
            imageWidth: 150,
            imageHeight: 150,
            imageUrl: confirmation,
            confirmButtonText: 'Continuar',
            showDenyButton: true,
            denyButtonText: `Salir sin guardar`,
            customClass: {
              actions: 'action-close-modal',
            },
            allowOutsideClick: false
    
          }).then(async (result) => {
            resolve(!result.isConfirmed)
          })
        })
    }

    useEffect(() => {
        const setAlertTypesAndFormat = async (alert) => {
            if (alert?.alert_type_ids && alert?.alert_type_ids.length > 0) {
                if (alertTypes.length < 1) {
                    await getAlertTypesData();
                    return "";
                }
                const alertTypeNamesAux = alert.alert_type_ids.map(id => {
                    const alertType = alertTypes.find(type => type.typeId === id);
                    return alertType ? alertType.name : "";
                }).join(', ');

                setAlertTypeNames(alertTypeNamesAux);
            }
        };

        if (fifthModal) {
            setAlertTypesAndFormat(alertToUpdate);
        }
    }, [fifthModal, alertToUpdate, alertTypes, getAlertTypesData]);

    useEffect(() => {
        if (parentFunctionToCallChild) {
            handleAlertToUpdate(alertToUpdate);
        }
    }, [parentFunctionToCallChild]);

    useEffect(() => {
        if (resetEditAlertData) {
            resetComponentData();
        }
    }, [parentFunctionToCallChild]);

    useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === 'Escape' && fifthModal) {
            e.preventDefault();
            if (closeFifthModal) {
                closeFifthModal();
            } else {
                toggleFifthModal();
            }
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [fifthModal, closeFifthModal, toggleFifthModal]);

    return (
        <div className={isEditView ? classes.hidden : ''}>            
            {
                !isHidden ? 
                (
                    <div className={classes.unfoldAlertsWrapper} style={{ height }}>
                        <div className={classes.unfoldAlertsCenterBtn} onClick={handleClickUnfold}>
                            <div className={classes.unfoldAlertsCenterContainer}>
                                <div className={classes.unfoldAlertsCenterTitleContainer}>
                                    <span className={classes.unfoldAlertsCenterTitle}>{`Gestor de alertas (${toSpanishNumberFormat(myAlertsCounter)})`}</span>
                                    <AlertTooltip tooltipContent={tooltipContent}>
                                        <img className={classes.infoIconForTootltip} alt="info" src={inforFortooltipIcon} />
                                    </AlertTooltip>
                                </div>
                                <div className={classes.unfoldAlertsCenterArrow}>
                                    <img className={isButtonHidden ? classes.plusIcon : classes.hidden} alt="Frame" src={PlusButton} onClick={toggleCreateAlertModalCaller} />
                                    <img className={classes.arrowIcon} alt="Frame" src={unfoldToRigthIcon} />
                                </div>                                                     
                            </div>                                         
                        </div>
                        <div className={classes.unfoldAlertsSection}>
                            <div className={classes.unfoldAlertsSectionAlertList}>
                                {alerts.map(alert => (
                                    <div key={alert.id} className={classes.alertCard}>
                                        <div className={classes.alertCardTitleContainer}>
                                            <span className={classes.alertCardTitle}>{alert.alert_name}</span>
                                        </div>
                                        <div className={classes.alertCardButtonContainer} onClick={() => handleSelectAlert(alert)}>
                                            <div className={classes.alertCardButton}>
                                                <span className={classes.alertCardButtonText}>Editar</span>
                                            </div>
                                        </div>
                                        {showOptions && selectedAlert && selectedAlert.alert_id === alert.alert_id && (
                                            <div ref={optionsMenuRef} className={classes.optionsMenu}>
                                                <div className={classes.optionItemFirst} onClick={() => handleEditAlertType(alert)}>Editar tipo de alerta</div>
                                                <div className={classes.separatorHorizontal}></div>
                                                <div className={classes.optionItem} onClick={() => handleEditAlertFrequency(alert)}>Editar frecuencia de envío</div>
                                                <div className={classes.separatorHorizontal}></div>
                                                <div className={classes.optionItem} onClick={() => handleEditAlertEmails(alert)}>Editar destinatario</div>
                                                <div className={classes.separatorHorizontal}></div>
                                                <div className={classes.optionItem} onClick={() => handleEditAlertName(alert)}>Renombrar alerta</div>
                                                <div className={classes.separatorHorizontal}></div>
                                                <div className={classes.optionItem} onClick={() => handleAddPublications(alert)}>Gestionar publicaciones</div>
                                                <div className={classes.separatorHorizontal}></div>
                                                <div className={classes.optionItemLast} onClick={() => handelDeleteAlert(alert)}>Eliminar alerta</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>                            
                        </div>
                    </div>
                )
            : 
                (
                    <div className={classes.foldAlertsCenterBtn} onClick={handleClickUnfold}>
                        <div className={classes.foldAlertsCenterContainer}>
                            <div className={classes.foldAlertsCenterTitleContainer}>
                                <span className={classes.foldAlertsCenterTitle}>{`Gestor de alertas (${toSpanishNumberFormat(myAlertsCounter)})`}</span>
                                <AlertTooltip tooltipContent={tooltipContent}>
                                    <img className={classes.infoIconForTootltip} alt="info" src={inforFortooltipIcon} />
                                </AlertTooltip>                                
                            </div>
                            <div className={classes.foldAlertsCenterArrow}>
                                <img className={isButtonHidden ? classes.foldPlusIcon : classes.hidden} alt="Frame" src={PlusButton} onClick={toggleCreateAlertModalCaller} />
                                <img className={classes.foldArrowIcon} alt="Frame" src={unfoldToRigthIcon} />
                            </div>                                                     
                        </div>                        
                    </div>
                )
            }  

            {firstModal && (
                <CustomModal
                    isOpen={firstModal}
                    toggle={toggleFirstModal}
                    onClose={closeFirstModalCloseButton}
                    title="Editar alerta"
                    customStyles={{ box: firstModalStyles.customModal }}
                    customClassNames={{
                        form: firstModalStyles.modal1CreacinDeAlerta,
                        header: classes.crearAlertaParent,
                        title: firstModalStyles.crearAlerta,
                        closeWrapper: classes.closeWrapper,
                        closeIcon: classes.closeIcon,
                        separatorContainer: firstModalStyles.separatorContainer,
                        separator: firstModalStyles.separator,
                        bodyContainer: firstModalStyles.modal1CreacinDeAlertaInner,
                        footerContainer: firstModalStyles.botonLoginWrapper,
                        box: classes.afterElementNone
                    }}
                    footerActions={
                        !loadingAlertTypes && (
                            firstModal && (
                                !loadingAlert && (
                                    <button
                                        type="button"
                                        className={firstModalStyles.submitButton}
                                        disabled={checkedAlertTypes?.length < 1}
                                        onClick={handleSaveChanges}
                                    >
                                        <b className={firstModalStyles.siguiente}>Guardar cambios</b>
                                    </button>
                                )
                            )
                        )
                    }
              >
                {
                    loadingAlertTypes ? (
                        <FirstAlertModalLoader />
                    ) : (
                        <section className={firstModalStyles.modal1CreacinDeAlertaInner}>
                            <div className={firstModalStyles.frameParent}>
                                <div className={firstModalStyles.seleccionaAlMenosUnTipoDeWrapper}>
                                    <div className={firstModalStyles.seleccionaAlMenos}>
                                        Selecciona al menos un tipo de alerta
                                    </div>
                                </div>
                                <div className={firstModalStyles.frameGroup}>
                                    {alertTypes && alertTypes.map((alert, index) => (
                                        <AlertTypeCard
                                        key={index}
                                        alertType={alert}
                                        isChecked={false}
                                        checkedAlertTypes={checkedAlertTypes}
                                        setCheckedAlertTypes={setCheckedAlertTypes}
                                        />
                                    ))}
                                </div>
                            </div>
                        </section>
                    )
                }
                {loadingAlert && (
                    <div className={classes.botonLoadingContainerFirst}>
                        <div className={classes.botonLoading} disabled={true}>
                            <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                            />
                            <b >Cargando</b>
                        </div>
                    </div>                  
                )}
              </CustomModal>
            )} 

            {secondModal && (
                <CustomModal
                isOpen={secondModal}
                toggle={toggleSecondModal}
                onClose={closeSecondModalCloseButton}
                title="Editar alerta"
                customStyles={{ box: secondModalStyles.customModal }}
                customClassNames={{
                  form: secondModalStyles.modalForm,
                  header: classes.crearAlertaParent,
                  title: classes.crearAlerta,
                  closeWrapper: classes.closeWrapper,
                  closeIcon: classes.closeIcon,
                  separatorContainer: secondModalStyles.separatorContainer,
                  separator: secondModalStyles.separator,
                  bodyContainer: secondModalStyles.bodyContainer,
                  footerContainer: secondModalStyles.footerContainer,
                  box: classes.afterElementNone
                }}
                footerActions={
                    !loadingAlert && (
                        <div
                            className={`${secondModalStyles.botonLogin} ${isButtonEnabled ? secondModalStyles.enabled : ''}`}
                            onClick={isButtonEnabled ? handleSaveChanges : null}
                        >
                            <b className={secondModalStyles.siguiente}>Guardar cambios</b>
                        </div>
                    )
                }
              >
                <div className={secondModalStyles.seleccionaLaFrecuencia}>Selecciona la frecuencia de alerta</div>
                <div className={secondModalStyles.elEnvoEst}>(El envío está sujeto a la lógica del tipo de alerta)</div>
                <div className={secondModalStyles.correo}>Se repite el</div>
                <div className={secondModalStyles.rectangleParentComponent}>
                  {days.map((day) => (
                    <div
                      key={day.key}
                      className={`${secondModalStyles.rectangleParent} ${activeDays[day.key] ? secondModalStyles.active : ''}`}
                      onClick={() => toggleDay(day.key)}
                    >
                      <div className={secondModalStyles.groupChild} />
                      <div className={`${secondModalStyles.dayText} ${activeDays[day.key] ? secondModalStyles.activeText : ''}`}>{day.label}</div>
                    </div>
                  ))}
                </div>
                <div className={secondModalStyles.correo1}>Fecha</div>   
                <div className={secondModalStyles.rectangleParent2} onClick={toggleDatePicker}>
                  <div className={secondModalStyles.groupChild2}>                                            
                    <div className={secondModalStyles.seleccionaUnaFecha}>
                      {startDate || endDate ? formatDateRange(startDate, endDate) : "Selecciona una fecha"}
                    </div>   
                    <img className={secondModalStyles.calendarIcon} alt="" src={CalendarPicker} />                                                                                         
                  </div>
                  <div className={secondModalStyles.dynamicText}>
                    {activeDays && Object.values(activeDays).some(day => day) && startDate ? (
                      <span>
                        Se enviará todos los {days.filter(day => activeDays[day.key]).map(day => day.fullLabel.toLowerCase()).join(', ')} desde el {formatDate(startDate)}
                        {endDate ? ` hasta el ${formatDate(endDate)}` : ''}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>                                                                                                                      
                </div>                                    
                {showDatePicker && (
                  <div className={secondModalStyles.datePickerOverlay}>
                    <DatePicker 
                      selectedDate={selectedDate} 
                      onDateChange={handleDateChange} 
                      onClose={toggleDatePicker}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate} 
                      setEndDate={setEndDate}
                      firstClick={firstClick}
                      setFirstClick={setFirstClick}
                    />
                  </div>
                )}
                {loadingAlert && (
                    <div className={classes.botonLoadingContainerSecond}>
                        <div className={classes.botonLoading} disabled={true}>
                            <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                            />
                            <b >Cargando</b>
                        </div>
                    </div>                  
                )}
              </CustomModal>
            )} 

            {thirdModal && (
                <CustomModal
                isOpen={thirdModal}
                toggle={toggleThirdModal}
                onClose={closeThirdModalCloseButton}
                title="Editar alerta"
                customStyles={{ box: thirdModalStyles.customModal }}
                customClassNames={{
                  form: thirdModalStyles.modalForm,
                  header: classes.crearAlertaParent,
                  title: classes.crearAlerta,
                  closeWrapper: classes.closeWrapper,
                  closeIcon: classes.closeIcon,
                  separatorContainer: thirdModalStyles.separatorContainer,
                  separator: thirdModalStyles.separator,
                  bodyContainer: thirdModalStyles.bodyContainer,
                  footerContainer: thirdModalStyles.footerContainer,
                  box: classes.afterElementNone
                }}
                footerActions={
                  !loadingAlert && (
                    <div
                        className={`${thirdModalStyles.botonLogin} ${(checkedEmails?.length > 0 && isCheckedTerms) ? thirdModalStyles.enabled : ''}`}
                        onClick={(checkedEmails?.length > 0 && isCheckedTerms) ? handleSaveChanges : null}
                    >
                        <b className={thirdModalStyles.siguiente}>Guardar cambios</b>
                    </div>)
                }
              >
                <div className={thirdModalStyles.seleccionaDestinatarioContainer}>
                  <div className={thirdModalStyles.seleccionaDestinatario}>Selecciona un destinatario</div>
                </div>
                <div className={thirdModalStyles.addEmailButtonContainer}>
                  <div className={thirdModalStyles.addEmailButton}>
                    <div onClick={handleAddEmailModalReturn} className={thirdModalStyles.addEmailButtonElements}>
                      <img className={thirdModalStyles.plusIcon} src={Plus} />
                      <b className={thirdModalStyles.addEmail}>Agregar correo</b>
                    </div>
                  </div>
                </div>
                <div className={thirdModalStyles.emailsContainer}>
                  {emails && emails.map((email, index) => (
                    <EmailCheckbox 
                      key={index}
                      email={email}
                      checked={false}
                      checkedEmails={checkedEmails}
                      setCheckedEmails={setCheckedEmails}
                    />
                  ))}
                </div>
                <div className={thirdModalStyles.terminosCondicionesContainer}>
                  <div className={thirdModalStyles.terminosCondiciones}>
                    <TermAndConditions
                      isCheckedTerms={isCheckedTerms}
                      setIsCheckedTerms={setIsCheckedTerms}
                    />
                  </div>
                </div>
                {loadingAlert && (
                    <div className={classes.botonLoadingContainer}>
                        <div className={classes.botonLoading} disabled={true}>
                            <CircularProgress
                            thickness={6}
                            size={33}
                            sx={{
                                color: "#57C2BD",
                                position: 'relative',
                            }}
                            />
                            <b >Cargando</b>
                        </div>
                    </div>                  
                )}
              </CustomModal>
            )} 

            {addEmailModal && (
                <CustomModal
                isOpen={addEmailModal}
                toggle={toggleAddEmailModal}
                onBack={handleAddEmailModalReturn}
                onClose={closeAddEmailModalCloseButton}
                title="Editar alerta"
                customStyles={{ box: addEmailModalStyles.customModal }}
                customClassNames={{
                  form: addEmailModalStyles.modalForm,
                  header: addEmailModalStyles.crearAlertaParent,
                  title: addEmailModalStyles.crearAlerta,
                  closeWrapper: addEmailModalStyles.closeWrapper,
                  backIcon: addEmailModalStyles.backIcon,
                  closeIcon: addEmailModalStyles.closeIcon,
                  separatorContainer: addEmailModalStyles.separatorContainer,
                  separator: addEmailModalStyles.separator,
                  bodyContainer: addEmailModalStyles.bodyContainer,
                  footerContainer: addEmailModalStyles.footerContainer,
                  box: classes.afterElementNone
                }}
                footerActions={
                  <div
                    className={`${addEmailModalStyles.botonLogin} ${isValidEmail(inputEmail) ? addEmailModalStyles.enabled : ''}`}
                    onClick={() => handleAddEmail(inputEmail)}
                    disabled={!isValidEmail(inputEmail)}
                  >
                    <b className={addEmailModalStyles.siguiente}>Agregar</b>
                  </div>
                }
              >
                  <div className={addEmailModalStyles.seleccionaDestinatarioContainer}>
                      <div className={addEmailModalStyles.seleccionaDestinatario}>Ingresa una dirección de correo</div>
                  </div>         
                  <div className={addEmailModalStyles.emailInputContainer}>
                    <span className={addEmailModalStyles.inputCorreo}>Correo</span>
                    <div className={addEmailModalStyles.inputBorderContainer}>
                      <input
                        type="email"
                        className={addEmailModalStyles.emailInput}
                        value={inputEmail}
                        onChange={handleInputEmailChange}
                        placeholder="Ingresa aquí tu correo electrónico"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent form submission
                                if (isValidEmail(inputEmail)) {
                                    handleAddEmail(inputEmail);
                                }
                            }
                        }}
                      />
                    </div>
                  </div>
              </CustomModal>
            )}

            {fourthModal && (
                <CustomModal
                isOpen={fourthModal}
                toggle={toggleFourthModal}
                onClose={closeFourthModalCloseButton}
                title="Editar alerta"
                customStyles={{ box: fourthModalStyles.customModal }}
                customClassNames={{
                    form: fourthModalStyles.modalForm,
                    header: classes.crearAlertaParent,
                    title: classes.crearAlerta,
                    closeWrapper: classes.closeWrapper,
                    closeIcon: classes.closeIcon,
                    separatorContainer: fourthModalStyles.separatorContainer,
                    separator: fourthModalStyles.separator,
                    bodyContainer: fourthModalStyles.bodyContainer,
                    footerContainer: fourthModalStyles.footerContainer,
                    box: classes.afterElementNone
                }}
                footerActions={
                  !loadingAlert && (
                    <div
                      className={`${fourthModalStyles.botonLogin} ${(inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) ? fourthModalStyles.enabled : ''}`}
                      onClick={() => {
                        if (inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) {
                            handleAlertName(newAlertName);
                        }
                      }}                     
                    >
                      <b className={fourthModalStyles.siguiente}>Guardar cambios</b>
                    </div>
                  )
                }
              >
                  <div className={fourthModalStyles.seleccionaDestinatarioContainer}>
                      <div className={fourthModalStyles.seleccionaDestinatario}>¡Nombra tu alerta!</div>
                  </div>        
                  <div className={fourthModalStyles.emailInputContainer}>
                    <span className={hasDuplicateNameError ? fourthModalStyles.inputNameError : fourthModalStyles.inputName}>
                      Nombre de tu alerta
                      <span className={hasDuplicateNameError ? fourthModalStyles.inputNameMaxError : fourthModalStyles.inputNameMax}>
                        (máximo 35 caracteres)
                      </span>
                    </span>
                    <div className={hasDuplicateNameError ? fourthModalStyles.inputBorderContainerError : fourthModalStyles.inputBorderContainer}>
                      <input
                        className={fourthModalStyles.emailInput}
                        value={inputEditAlertName}
                        onChange={handleInputAlertNameChange}
                        placeholder="Ingresa aquí el nombre de tu alerta"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent form submission
                                if (inputEditAlertName.length > 0 && inputEditAlertName.length <= 35 && originalAlertName !== inputEditAlertName) {
                                    handleAlertName(newAlertName);
                                }
                            }
                        }}
                      />
                    </div>
                    {hasDuplicateNameError && (
                      <span className={fourthModalStyles.nameExistsError}>Este nombre ya existe</span>
                    )}
                  </div>
                {loadingAlert && (
                  <div className={fourthModalStyles.botonLoading} disabled={true}>
                    <CircularProgress
                      thickness={6}
                      size={33}
                      sx={{
                        color: "#57C2BD",
                        position: 'relative',             
                      }}
                    />
                    <b className={fourthModalStyles.siguiente}>Cargando</b>
                  </div>
                )}
              </CustomModal>
            )}

            {fifthModal && (
                <Modal isOpen={fifthModal} toggle={toggleFifthModal} backdrop="static">
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: "676px",
                        height: "663px",
                        bgcolor: 'white',
                        overflow: "hidden",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '17px',
                        zIndex: 1,
                        border: '1px solid #d3d3d3',
                        '@media (max-width:650px)': {
                            width: '90%', // width when screen size is less than 700px
                        },
                    }}
                    >
                        <div className={classes.fifthModal}>
                            <div className={classes.fifthModalHeader}>
                                <div className={classes.fifthModalHeaderTitle}>
                                    <div className={classes.fifthModalHeaderTitleText}>
                                        {alertToUpdate?.alert_name}
                                    </div>
                                    <div className={classes.fifthModalHeaderClose}>
                                        <img className={classes.fifthModalHeaderCloseIcon} src={Close} onClick={closeFifthModal} />
                                    </div>
                                </div>
                                <div className={classes.fifthModalHeaderAlert}>
                                    <div className={classes.fifthModalHeaderAlertDate}>
                                        {activeDays && Object.values(activeDays).some(day => day) && startDate ? (
                                            <span>
                                                Envío los {days.filter(day => activeDays[day.key]).map(day => day.fullLabel.toLowerCase()).join(', ')} a partir del {formatDate(startDate)}
                                                {endDate ? ` hasta el ${formatDate(endDate)}` : ''}
                                            </span>
                                            ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className={classes.fifthModalHeaderAlertType}>
                                        {`Tipo de alerta: ${alertTypeNames ?? ''}`}
                                    </div>
                                    <div className={classes.fifthModalHeaderAlertEmail}>
                                        {`Se envía a: ${emailString ?? ''}`}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.fifthModalContent}>
                                {loadingPublications && (
                                    <div className={classes.fifthModalContentPublications}>
                                        <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                        <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                        <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                        <Skeleton variant="rectangular" width="100%" height={69} style={{ marginBottom: '5px' }} />
                                    </div>
                                )}
                                {!loadingPublications && (
                                    <div className={classes.fifthModalContentPublications}>
                                        {publications.length > 0 ? (
                                            publications.map((publication, index) => (
                                                <div key={index} className={classes.fifthModalPublicationsCard}>
                                                    <div className={classes.publicationCardImage}>
                                                        <img className={classes.publicationImage} src={publication.images ? (publication.images[0]?.link ?? '') : ''} alt={publication.name} />
                                                    </div>
                                                    <div className={classes.publicationCardName}>
                                                        {publication.name}
                                                    </div>
                                                    <div className={classes.publicationCardCodes}>
                                                        <span>ID: {publication.id_publication}</span>
                                                        <span>SKU: {publication.sku}</span>                                                
                                                    </div>
                                                    <div className={classes.publicationCardDelete}>
                                                        <img className={classes.publicationCardDeleteIcon} src={Delete} onClick={() => handleDeletePublication(publication.id_publication)} />
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className={classes.noPublicationsMessage}>
                                                <div className={classes.noResultsImageContainer}>
                                                    <img src={noPublicationsInAlertImg} className={classes.noResultsImage} />
                                                </div>  
                                                <div className={classes.noDataFoundTextContainer}>
                                                    <span className={classes.noDataFoundText}>{"La alerta no tiene publicaciones"}</span>
                                                </div>                                                                                              
                                            </div>
                                        )}
                                    </div>
                                )}                               
                                <div className={isPaginationActive && publications?.length > 0 && !loadingPublications ? classes.fifthModalContentPagination : `${classes.fifthModalContentPagination} ${classes.paginationDisabled}`}>
                                    <div className={classes.fifthModalPaginationInner}>
                                        <img className={classes.paginationLeftArrow} alt="Prev" src={unfoldToRigthIcon} onClick={() => {
                                            if (paginationPage > 1 && isPaginationActive && publications?.length > 0 && !loadingPublications) {
                                                handlePreviousPage();
                                            }
                                        }}/>
                                        <span className={classes.paginationText} >{`${paginationPage} - ${selectedAlert?.alert_publications?.length < 100 ? 1 : finalPaginationPage}`}</span>
                                        <img className={classes.paginationRightArrow} alt="Next" src={unfoldToRigthIcon} onClick={() => {
                                            if (paginationPage < finalPaginationPage && isPaginationActive && publications?.length > 0 && !loadingPublications) {
                                                handleNextPage();
                                            }
                                        }} />
                                    </div>                                    
                                </div>
                            </div>
                            <div className={classes.fifthModalFooter}>
                                <div className={loadingPublications ? classes.fifthModalFooterButtonDisabled : classes.fifthModalFooterButton} onClick={() => handleNewPublications()}>
                                    Agregar
                                </div>
                            </div>                            
                        </div>
                    </Box>
              </Modal>
            )}

            {sixthModal && (
                <Modal isOpen={sixthModal} toggle={toggleSixthModal} backdrop="static">
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%)',
                        width: "540px",
                        height: "574px",
                        bgcolor: 'white',
                        overflow: "hidden",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '17px',
                        zIndex: 1,
                        border: '1px solid #d3d3d3',
                        '@media (max-width:700px)': {
                            width: '90%', // width when screen size is less than 700px
                        },
                    }}
                    >
                        <div className={classes.sixthModal}>
                            <div className={classes.sixthModalHeader}>
                                <div className={classes.sixthModalHeaderClose}>
                                    <img className={classes.sixthModalHeaderCloseIcon} src={Close} onClick={closeSixthModal} />
                                </div>                                
                            </div>
                            <div className={classes.sixthModalContent}>
                                <div className={classes.sixthModalContentImage}>
                                    <img className={classes.sixthModalImage} src={Interrogation}/>
                                </div>
                                <div className={classes.sixthModalContentTitle}>
                                    <span>¿Seguro que...</span>
                                </div>
                                <div className={classes.sixthModalContentText}>
                                    <span>Deseas eliminar esta</span>
                                </div>
                                <div className={classes.sixthModalContentSmallText}>
                                    <span>alerta?</span>
                                </div>
                            </div>
                            <div className={classes.sixthModalFooter}>
                                <div className={classes.sixthModalFooterButton} onClick={() => handelDeleteAlertRequest()}>
                                    Eliminar
                                </div>
                                {loadingAlert && (
                                    <div className={classes.botonLoading} disabled={true}>
                                        <CircularProgress
                                        thickness={6}
                                        size={33}
                                        sx={{
                                            color: "#57C2BD",
                                            position: 'relative',
                                        }}
                                        />
                                        <b >Cargando</b>
                                    </div>                
                                )}
                            </div>                   
                        </div>
                    </Box>
              </Modal>
            )}
        </div>
    )
}

export default AlertsCenter