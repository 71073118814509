const configFile = require('../config/url');
import { formatDate, performanceMetric } from "../utils/utils";

export const getPublications = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {          
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambientePim}publications${query}`, requestOptions)
        performanceMetric("/publications", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}

export const getBuyboxFile = async (store, country) => {

    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKey)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    today = formatDate(today);
    yesterday = formatDate(yesterday)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambiente}acceleration/buybox?store=${store}&type=xlsx&country=${country}&dateFrom=${yesterday}&dateTo=${today}`, requestOptions)
        performanceMetric("/acceleration/buybox", "GET", "mtd", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}

export const getPublicationsFile = async (queryString) => {

    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }
    
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}publications?format_type=xlsx${queryString || ""}`, requestOptions)
        performanceMetric("/publications", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);

        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}

export const getFilters = async () => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyPim)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }
    
    try {
        const start = Date.now(); 
        const response = await fetch(`${configFile.config.ambientePim}filters/publications`, requestOptions)
        performanceMetric("/filters/publications", "GET", "pim", localStorage.getItem("name"), Date.now()-start, accessToken);

        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}

export const getPublicationsAnalytics = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }
    
    try {                    
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}publication_analytics${query}`, requestOptions)
        performanceMetric("/publication_analytics", "GET", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}

export const getPublicationsAnalyticsExcel = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }
    
    try {               
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}publication_analytics/generate_excel_rivals${query}`, requestOptions)
        performanceMetric("/publication_analytics/generate_excel_rivals", "GET", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}


export const deletePublicationCompetition = async (query = '') => {
    const accessToken = localStorage.getItem('Token')
    const bearerToken = `Bearer ${accessToken}`

    const myHeaders = new Headers()
    myHeaders.append('x-api-key', configFile.config.apiKeyAnalytics)
    myHeaders.append('Authorization', `${bearerToken}`)
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'delete',
        headers: myHeaders,
        redirect: 'follow'
    }
    
    try {              
        const start = Date.now();
        const response = await fetch(`${configFile.config.ambienteAnalytics}publication_analytics${query}`, requestOptions)
        performanceMetric("/publication_analytics", "DELETE", "analytics", localStorage.getItem("name"), Date.now()-start, accessToken);
        if (!response.ok) {
            const data = await response.json()
            if (data.internal_code === "server_error") {
                throw new Error('Existe algun problema en el servidor')
            } else {
                throw new Error('Algo salió mal. Inténtalo de nuevo más tarde.')
            }
        }
        if (response.ok) return await response.json()
    }
    catch (err) {
        return err
    }
}
