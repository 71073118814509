import React from 'react';
import classes from './PublicationComparisonLoader.module.css';
import { Skeleton } from '@mui/material';


const PublicationComparisonLoader = () => {    

    return (
        <div className={classes.mainContainer}>
            <div className={classes.publicationContainer}>
                <div className={classes.publicationTitle}>
                    <Skeleton width="90%" height="70%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                </div>
                <div className={classes.publicationImage}>
                    <div className={classes.publicationImageLeft}>
                        <Skeleton width="100%" height="70%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="15%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div>
                    <div className={classes.publicationImageRight}>
                        <Skeleton width="90%" height="17%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="45%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />                    
                        <Skeleton width="60%" height="17%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div>                    
                </div>
                <div className={classes.publicationContent}>
                    <div className={classes.publicationContentLeft}>
                        <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div>
                    <div className={classes.publicationContentRight}>                    
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="100%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div> 
                </div>
            </div>
            <div className={classes.secondContainer}>
                <div className={classes.graphContainer}>
                    <div className={classes.graphContainerTop}>
                        <Skeleton width="5%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="80%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="30%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="50%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="60%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="50%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="45%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="35%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="20%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="15%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="59%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        <Skeleton width="5%" height="29%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div>
                    <div className={classes.graphContainerBottom}>
                        <Skeleton width="90%" height="70%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <div className={classes.tableContainerLeft}>
                        <div className={classes.tableContainerLeftTop}>
                            <Skeleton width="80%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                        </div> 
                        <div className={classes.tableContainerLeftCenter1}>
                            <div className={classes.tableLeftContent}>
                                <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                            <div className={classes.tableRightContent}>
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                        </div> 
                        <div className={classes.tableContainerLeftCenter2}>
                            <div className={classes.tableLeftContent}>
                                <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                            <div className={classes.tableRightContent}>
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                        </div> 
                        <div className={classes.tableContainerLeftBottom}>
                            <div className={classes.tableLeftContent}>
                                <Skeleton width="100%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                            <div className={classes.tableRightContent}>
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="100%" height="40%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div> 
                        </div> 
                    </div> 
                    <div className={classes.tableContainerRight}>
                        <div className={classes.tableContainerRightBig1}>
                            <div className={classes.tableContainerRightBigTop}>
                                <Skeleton width="80%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                            <div className={classes.tableContainerRightBigBottom}>
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                        </div>
                        <div className={classes.tableContainerRightBig1}>
                            <div className={classes.tableContainerRightBigTop}>
                                <Skeleton width="80%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                            <div className={classes.tableContainerRightBigBottom}>
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                        </div>
                        <div className={classes.tableContainerRightBig1}>
                            <div className={classes.tableContainerRightBigTop}>
                                <Skeleton width="80%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                            <div className={classes.tableContainerRightBigBottom}>
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                        </div>
                        <div className={classes.tableContainerRightBig1}>
                            <div className={classes.tableContainerRightBigTop}>
                                <Skeleton width="80%" height="100%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                            <div className={classes.tableContainerRightBigBottom}>
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                                <Skeleton width="80%" height="12%" style={{transform: "scale(1, 1)", opacity: 0.35, borderRadius: '5px'}} />
                            </div>
                        </div> 
                    </div> 
                </div>            
            </div>        
        </div>
    );
}    

export default PublicationComparisonLoader;
