import React, { useEffect, useState } from 'react';
import classes from './CompetitionInfoCard.module.css';

import Redirect from '../../../assets/img/icons/redirect-vector.svg';

const CompetitionInfoCard = ({ imageUrl, category, name, code, iconUrl, publicationUrl }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleRedirectClick = (event) => {
        event.stopPropagation();
        const url = publicationUrl;
        if (url) {
            window.open(url, '_blank');
        }
    };

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={classes.competitionInfo}>
            <div className={classes.competitionImageContainer}>
                <img src={imageUrl} width={windowWidth < 1300 ? '80%' : '100%'} alt={name} className={classes.competitionImage} />
            </div>
            <div className={classes.competitionDetails}>
                <div className={classes.competitionCategoryContainer}>
                    <span className={classes.competitionCategory}>{category}</span>
                </div>
                <div className={classes.competitionNameContainer}>
                    <span className={classes.competitionName}>{name}</span>
                </div>
                <div className={classes.competitionCodeContainer}>
                    <span className={classes.competitionCode}>ID: {code}</span>
                </div>
            </div>
            <div className={classes.competitionIconContainer}>
                <img className={classes.channelIcon} src={iconUrl} />
                <img className={classes.cardInfoRedirectIcon} src={Redirect} alt="Redirect Icon" onClick={handleRedirectClick} />
            </div>                
        </div>
    );
};

export default CompetitionInfoCard;
